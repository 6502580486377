//PurchaseConfirmation.js

import React from 'react';
import { Container, Row, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

const PurchaseConfirmation = () => {
  const location = useLocation();
  const { event, selectedTickets, selectedSeats } = location.state;

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <h1>Purchase Confirmation</h1>
      </Row>
      <Row className="mt-4">
        <p>Thank you for purchasing tickets for {event.EventName}!</p>
        <p>Number of tickets: {selectedTickets}</p>
        {selectedSeats.length > 0 && (
          <p>Selected Seats: {selectedSeats.join(', ')}</p>
        )}
      </Row>
    </Container>
  );
};

export default PurchaseConfirmation;