// OrderConfirmation.js

// OrderConfirmation.js

import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, Card, Alert } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_ENDPOINTS } from '../config';
import SocialShare from './utils/SocialShare';

const OrderConfirmation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { event, selectionType, selectedTickets, totalWithFees, buyerInfo, paymentReference } = location.state || {};
    
    //console.log('event',event)
    //console.log('event PosterImagePath',event?.PosterImagePath)
    //console.log('selectionType',selectionType)
    //console.log('selectedTickets',selectedTickets)


    const [emailSent, setEmailSent] = useState(() => sessionStorage.getItem('emailSent') === 'true');
    const [ticketReduction, setTicketReduction] = useState(() => sessionStorage.getItem('ticketReduction') === 'true');
    const [customerInfoSaved, setCustomerInfoSaved] = useState(() => sessionStorage.getItem('customerInfoSaved') === 'true');
    const [emailError, setEmailError] = useState(null);
    const [timeLeft, setTimeLeft] = useState(0);
    
    // Timer logic for redirecting to home page after expiration
    useEffect(() => {
        const now = Date.now();
        let expirationTime = localStorage.getItem('confirmationExpirationTime');
        
        if (!expirationTime) {
            expirationTime = now + 10 * 60 * 1000; // 10 minutes
            localStorage.setItem('confirmationExpirationTime', expirationTime);
        }
        
        const timerInterval = setInterval(() => {
            const remainingTime = Math.max((expirationTime - Date.now()) / 1000, 0);
            setTimeLeft(remainingTime);
            
            if (remainingTime <= 0) {
                clearInterval(timerInterval);
                localStorage.removeItem('confirmationExpirationTime');
                navigate('/');
            }
        }, 1000);
        
        return () => clearInterval(timerInterval);
    }, [navigate]);
    
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };
    
    // Function to send the confirmation email
    const sendEmail = useCallback(async () => {
        if (emailSent) return;
        
        try {
            const emailPayload = {
                emailType: 'purchase',
                emailData: {
                    buyerName: `${buyerInfo?.firstName} ${buyerInfo?.lastName}`,
                    buyerEmail: buyerInfo?.email,
                    eventName: event?.EventName,
                    eventOrganizer: event?.OrganizerName,
                    eventDate: new Date(event?.EventDate).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                    }),
                    eventTime: `${event?.StartTime} - ${event?.EndTime}`,
                    doorsOpenTime: event?.DoorsOpenTime,
                    venue: `${event?.VenueName}, ${event?.VenueAddress}`,
                    type: Object.values(selectedTickets)
                        .map(details => details.ticketType || details.tableNumber || 'Unknown')
                        .join(', '),
                    quantity: Object.values(selectedTickets).map(details => details.quantity).join(', '),
                    price: Object.values(selectedTickets)
                        .map(details => `$${details.price.toFixed(2)}`) // Ensuring prices have 2 decimal points
                        .join(', '),
                    totalAmount: `$${totalWithFees.toFixed(2)}`, // Formatting the total amount to 2 decimal points
                    posterImagePath: `https://eventtop.ca/api1${event?.PosterImagePath}`,
                    },
                    };
                    
                    //console.log('emailData.posterImagePath',emailPayload.emailData.posterImagePath)
                    
                    const response = await fetch(`${API_ENDPOINTS.sendMail}`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(emailPayload),
                    });
                    
                    if (response.ok) {
                        setEmailSent(true);
                        sessionStorage.setItem('emailSent', 'true');
                    } else {
                        throw new Error('Failed to send email');
                    }
                    } catch (error) {
                        setEmailError('Failed to send confirmation email. Contact info@eventtop.ca for assistance.');
                        console.error('Error sending email:', error);
                    }
                    }, [emailSent, API_ENDPOINTS.sendMail, buyerInfo, event, selectedTickets, totalWithFees]);
                    
                    // Function to save customer information
                    const saveCustomerInfo = useCallback(async () => {
                        if (customerInfoSaved) return;
                        
                        //console.log('selectedTickets in saveCustomerInfo',selectedTickets)
                        //console.log('userID in saveCustomerInfo',localStorage.getItem('userID'))
                        
                        try {
                            
                            const userID = localStorage.getItem('userId');
                            if (!userID) {
                                console.warn('Warning: userID not found in local storage.');
                            }
                            
                            const customerData = {
                                eventID: event.EventID,
                                firstName: buyerInfo.firstName,
                                lastName: buyerInfo.lastName,
                                email: buyerInfo.email,
                                phone: buyerInfo.phone,
                                address: buyerInfo.address,
                                ticketType: Object.keys(selectedTickets)
                                .map(ticketId => selectedTickets[ticketId]?.ticketType || selectedTickets[ticketId]?.tableNumber || 'Unknown')
                                .join(', '),
                                quantity: Object.values(selectedTickets).reduce((acc, ticket) => acc + ticket.quantity, 0),
                                totalAmount: totalWithFees,
                                paymentReference: paymentReference || 'N/A',
                                userID: userID || null, // Add userID from localStorage or pass null
                            };
                            
                            const response = await fetch(`${API_ENDPOINTS.saveCustomerInfo}`, {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify(customerData),
                            });
                            
                            if (response.ok) {
                                setCustomerInfoSaved(true);
                                sessionStorage.setItem('customerInfoSaved', 'true');
                            } else if (response.status === 409) {
                                console.log('Customer information already saved.');
                            } else {
                                throw new Error('Failed to save customer information');
                            }
                        } catch (error) {
                            console.error('Error saving customer info:', error);
                        }
                    }, [customerInfoSaved, API_ENDPOINTS.saveCustomerInfo, event, buyerInfo, selectedTickets, totalWithFees, paymentReference]);
                    
                    // Function to reduce seats for banquet events
                    const reduceSeatsForBanquet = useCallback(async () => {
                        if (ticketReduction) return;
                        
                        //console.log('reduceSeatsForBanquet is called')
                        //console.log('selectedTickets in banq',selectedTickets)
                        
                        try {
                            const seatData = Object.values(selectedTickets).map(details => ({
                                TableID: details.tableId,
                                TableNumber: details.tableNumber,
                                TableNumber: details.tableType,
                                SeatsToReduce: details.quantity,
                            }));
                            
                            //console.log('seatData',seatData)
                            
                            const response = await fetch(`${API_ENDPOINTS.reduceBanquetSeats}`, {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({ eventID: event.EventID, tables: seatData }),
                            });
                            
                            if (response.ok) {
                                setTicketReduction(true);
                                sessionStorage.setItem('ticketReduction', 'true');
                            } else throw new Error('Failed to update banquet seat availability');
                        } catch (error) {
                            console.error('Error reducing banquet seats:', error);
                        }
                    }, [event, selectedTickets, ticketReduction, API_ENDPOINTS.reduceBanquetSeats]);
                    
                    
                    // Function to reduce ticket availability for general admission
                    const reduceTicketAvailability = useCallback(async () => {
                        if (ticketReduction) return;
                        
                        //console.log('reduceTicketAvailability is called')

                        try {
                            const updateData = {
                                eventID: event.EventID,
                                tickets: Object.entries(selectedTickets).map(([ticketId, details]) => ({
                                    ticketID: ticketId,
                                    quantity: details.quantity,
                                })),
                            };
                            
                            const response = await fetch(`${API_ENDPOINTS.reduceTicketAvailability}`, {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify(updateData),
                            });
                            
                            if (response.ok) {
                                setTicketReduction(true);
                                sessionStorage.setItem('ticketReduction', 'true');
                            } else throw new Error('Failed to update ticket availability');
                        } catch (error) {
                            console.error('Error reducing ticket availability:', error);
                        }
                    }, [ticketReduction, event, selectedTickets, API_ENDPOINTS.reduceTicketAvailability]);
                    
                    // Manage ticket or seat reduction based on selectionType
                    useEffect(() => {
                        if (paymentReference && !ticketReduction) {
                            if (selectionType === 'banquetHall') {
                                try {
                                    reduceSeatsForBanquet();
                                } catch (error) {
                                    console.error('Error reducing seats for banquet:', error.message);
                                }
                            } else if (selectionType === 'generalAdmission' || selectionType === 'concert') {
                                try {
                                    reduceTicketAvailability();
                                } catch (error) {
                                    console.error('Error reducing ticket availability:', error.message);
                                }
                            } else {
                                console.error(`Unhandled selection type: ${selectionType}`);
                            }
                        }
                    }, [selectionType, paymentReference, ticketReduction, reduceSeatsForBanquet, reduceTicketAvailability]);
                    
                    useEffect(() => {
                        if (paymentReference && !customerInfoSaved) saveCustomerInfo();
                    }, [paymentReference, customerInfoSaved, saveCustomerInfo]);
                    
                    useEffect(() => {
                        if (paymentReference && !emailSent) sendEmail();
                    }, [paymentReference, emailSent, sendEmail]);
                    
                    return (
                        <Container className="mt-5">
                        <div className="p-4 bg-light shadow-lg rounded" style={{ backgroundColor: '#f9f9f9', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)' }}>
                        <h2 className="text-center mb-4">Thank You for Your Purchase!</h2>
                        
                        {/* Display warning if email sending failed */}
                        {emailError && (
                            <Alert variant="warning" className="text-center">
                            <strong>Warning:</strong> We were unable to send your confirmation email. Please contact 
                            <a href="mailto:info@eventtop.ca"> info@eventtop.ca</a> to receive a copy of your ticket.
                            </Alert>
                        )}
                        
                        <Row>
                        {/* Left column: Customer, Event, and ticket details */}
                        <Col md={8}>
                        <h4>Customer Information</h4>
                        <div className="text mt-0"><strong>Name:</strong> {buyerInfo?.firstName} {buyerInfo?.lastName}</div>
                        <div className="text mt-0"><strong>Email:</strong> {buyerInfo?.email}</div>
                        <div className="text mt-0"><strong>Phone:</strong> {buyerInfo?.phone}</div>
                        <div className="text mt-0 mb-2"><strong>Address:</strong> {buyerInfo?.address}</div>
                        
                        {event && (
                            <>
                            <h4 className="mt-3">Event Information</h4>
                            <div className="text mt-0"><strong>Event Name:</strong> {event.EventName}</div>
                            <div className="text mt-0"><strong>Organizer:</strong> {event.OrganizerName}</div>
                            <p className="mb-0"><strong>Date/Time:</strong> {new Date(event.EventDate).toLocaleDateString('en-US', {
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                            })}, {event.StartTime} - {event.EndTime}</p>
                            <p className="mb-0"><strong>Doors Open:</strong> {event.DoorsOpenTime}</p>
                            <div className="text mt-0"><strong>Venue:</strong> {event.VenueName}, {event.VenueAddress}, {event.EventCity}</div>
                            </>
                        )}
                        
                        <h4 className="mt-3">Selected Tickets</h4>
                        
                        <ul className="list-group mb-3 shadow-sm">
                        {selectedTickets && Object.entries(selectedTickets).map(([ticketId, ticketDetails]) => (
                            <li key={ticketId} className="list-group-item">
                            <strong>Ticket:</strong>{' '}
                            {ticketDetails.ticketType ? ticketDetails.tableType : ticketDetails.tableNumber} |
                            <strong> Quantity:</strong> {ticketDetails.quantity} | 
                            <strong> Price:</strong> ${ticketDetails.price} + HST
                            
                            {/* Notes and Details specific to each ticket */}
                            {ticketDetails.Notes && ( 
                                <div className="mt-0">
                                <p className="mb-0"><strong>Notes:</strong> {ticketDetails.Notes}</p>
                                </div>
                            )}
                            {ticketDetails.details && (
                                <div className="mt-0">
                                <p className="mb-0"><strong>Details:</strong> {ticketDetails.details}</p>
                                </div>
                            )}
                            
                            </li>
                        ))}
                        </ul>
                        
                        
                        <h4 className="mt-3"><strong>Total Paid:</strong> CA${totalWithFees.toFixed(2)}</h4>
                        </Col>
                        
                        {/* Right column: Event poster */}
                        <Col md={4}>
                        <Card className="shadow-sm">
                        {event?.PosterImagePath ? (
                            <Card.Img
                            variant="top"
                            src={`${API_ENDPOINTS.API_URL}${event.PosterImagePath}`}
                            alt="Event Poster"
                            />
                        ) : (
                            <Card.Img
                            variant="top"
                            src="https://eventtop.ca/api1/uploads/placeholder_image.png"
                            alt="Default Poster"
                            />
                        )}
                        <Card.Body>
                        <Card.Title>{event.EventName}</Card.Title>
                        <Card.Text>{event.Theme}</Card.Text>
                        </Card.Body>
                        </Card>
                        </Col>
                        </Row>
                        
                        
                        <div className="alert alert-warning mb-0" role="alert">
                        <strong>Note</strong>: All tickets are final sale and <strong>non-refundable</strong>. For any inquiries, please contact the organizer.
                        </div>
                        
                        
                        <hr />
                        
                        <div className="mt-4 row">
                        {/* Left column: Additional Venue Information */}
                        <div className="col-md-8">
                        <h4 className="text-left">Additional Venue Information:</h4>
                        <ul className="list-group">
                        <li className="list-group-item">
                        <ul className="ms-3">
                        {selectionType === 'generalAdmission' && (
                            <li>
                            If your event requires a seat reservation, please visit the venue’s reservation page or contact the venue directly using the provided phone number.
                            </li>
                        )}                        
                        <li>
                        Explore the event’s dining options on the venue’s menu page if available.
                        </li>
                        <li>
                        {event.DoorsOpenTime
                            ? `Doors open for dinner and drinks at: ${event.DoorsOpenTime}, and the show starts at: ${event.StartTime}.`
                        : `Show starts at: ${event.StartTime}.`}
                        </li>
                        </ul>
                        </li>
                        </ul>
                        </div>
                        
                        {/* Right column: Barcode Image */}
                        <div className="col-md-4 mt-4 d-flex align-items-center justify-content-center">
                        
                        <div 
                        className="d-flex" 
                        style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL}/images/Eventtop-barcode.jpg)`,
                            backgroundSize: 'contain', // Use 'contain' to show the full image
                            backgroundRepeat: 'no-repeat', // Prevent the image from repeating
                            backgroundPosition: 'center', // Align the image to the left
                            height: '180px', // Adjust the height as necessary
                            width: '100%', // Make the div full width within its container
                            maxWidth: '300px' // Limit the maximum width for responsiveness
                        }}
                        >
                        </div>
                        
                        </div>
                        
                        
                        
                        
                        </div>
                        
                        <hr />
                        <SocialShare event={event} />
                        
                        </div>
                        </Container>
                    );
                    };
                    
                    export default OrderConfirmation;


                    