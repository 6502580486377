import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './ReactHeader.css'; // Ensure this includes proper styling

const ReactHeader = () => {
  // Static images for small screens
  const smallScreenImages = ['images/ProArts-vertical2.png'];

  // Array of images for rotating backgrounds (large screens)
  const artistImages = [
    'images/ProArts.png',
    'images/artist0.png',
    'images/header-background-2.png',
    'images/header-wild_night_club.jpg',
    'images/header-concert-crowd.jpg',
  ];

  // Images where text overlay should be hidden
  const noTextImages = ['images/ProArts.png'];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  // Handle screen size changes and image rotation
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    // Rotate images only on larger screens
    if (!isSmallScreen) {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % artistImages.length);
      }, 5000); // Change every 5 seconds

      return () => clearInterval(interval);
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [isSmallScreen, artistImages.length]);

  // Determine the current background image
  const currentImage = isSmallScreen
    ? smallScreenImages[currentImageIndex % smallScreenImages.length]
    : artistImages[currentImageIndex];
  const shouldShowText = !noTextImages.includes(currentImage);

  return (
    <main className="container-fluid p-0">
      <div
        className="p-1 p-md-5 text-light bg-body-secondary"
        style={{
          backgroundImage: `url(${currentImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          height: isSmallScreen ? '400px' : '480px', // Adjust height dynamically
          width: '100%',
        }}
      >
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col lg={12} className="px-2 my-4 text-left">
              {shouldShowText && (
                <>
                  <h1
                    className={`fw-bold fst-italic rounded text-shadow yeserva-font ${
                      isSmallScreen ? 'fs-3' : 'display-4'
                    }`}
                    style={{
                      fontSize: isSmallScreen ? '1.8rem' : undefined,
                      textAlign: isSmallScreen ? 'center' : undefined,
                      fontWeight: isSmallScreen ? '600' : undefined,
                      WebkitFontSmoothing: 'antialiased',
                      MozOsxFontSmoothing: 'grayscale',
                    }}
                  >
                    Host your Events With Us
                  </h1>
                  <h3
                    className={`text-shadow kavoon-font ${
                      isSmallScreen ? 'fs-5' : 'fs-2'
                    }`}
                    style={{
                      fontSize: isSmallScreen ? '1.2rem' : undefined,
                      textAlign: isSmallScreen ? 'center' : undefined,
                      WebkitFontSmoothing: 'antialiased',
                      MozOsxFontSmoothing: 'grayscale',
                    }}
                  >
                    <span className="top-text">Top Events,</span>
                    <span className="tickets-text">Top Tickets,</span>
                    <span className="with-text"> with </span>
                    <span className="eventtop-text">EventTop!</span>
                  </h3>
                </>
              )}
            </Col>

            {!isSmallScreen && (
              <div className="image-selectors p-1">
                {artistImages.map((_, index) => (
                  <button
                    key={index}
                    className={`image-selector ${index === currentImageIndex ? 'active' : ''}`}
                    onClick={() => setCurrentImageIndex(index)}
                  ></button>
                ))}
              </div>
            )}

            <Link to="/EventHostingPage">
              <div className="text-start">
                <Button
                  variant="btn btn-primary fst-italic"
                  className="mt-5"
                  style={{
                    height: isSmallScreen ? '45px' : '50px',
                    width: isSmallScreen ? '180px' : undefined,
                    fontSize: isSmallScreen ? '0.8rem' : undefined,
                    padding: isSmallScreen ? '5px' : '10px',
                    whiteSpace: 'nowrap',
                    border: '3px solid black',
                    boxShadow: '2px 2px 8px rgba(0, 0, 1, 0.9)',
                    textAlign: 'center',
                  }}
                >
                  Organize Your Event
                </Button>
              </div>
            </Link>
          </Row>
        </Container>
      </div>
    </main>
  );
};

export default ReactHeader;