import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Button, Alert, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { fetchWithAuth } from '../react-auth-app/auth';
import { API_ENDPOINTS } from '../config';
import { calculateConvenienceFee } from '../utils/convenienceFeeCalculator';
import { calculateProcessingFee } from '../utils/processingFeeCalculator';
import { calculateSalesTax } from '../utils/taxCalculator';
import { calculateTotal } from '../utils/totalCalculator';
import BanquetHallLayout from './utils/BanquetHallLayout';
import LegendDisplay from './utils/Legend';
import PricingSummary from './utils/PricingSummary';
import SelectedTables from './utils/SelectedTables';


const BanquetTableMap = ({ event }) => {
  const navigate = useNavigate();
  const [tableOptions, setTableOptions] = useState([]);
  const [selectedSeats, setSelectedSeats] = useState({});
  const [showAlert, setShowAlert] = useState(false);

  const fetchTableData = useCallback(async (eventId) => {
    try {
      const response = await fetchWithAuth(`${API_ENDPOINTS.getEventTables(eventId)}`);
      if (response.ok) {
        const data = await response.json();
        if (data && data.tables) {
          setTableOptions(data.tables);
        }
      } else {
        console.error(`Failed to fetch tables. Status code: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching event tables:', error);
    }
  }, []);

  useEffect(() => {
    if (event && event.EventID) {
      fetchTableData(event.EventID);
    } else {
      console.warn('Event or event.EventID is missing.');
    }
  }, [event, fetchTableData]);

  const handleSeatSelection = (selection) => {
    const { tableId, tableNumber, tableType, price, totalSeats, kidSeats, totalAmount, totalPrice } = selection;

    //console.log('Seat Selection Input:', selection); // Debugging log

    // Ensure all required fields are present
    if (!tableId || !tableNumber || !price || !totalSeats || totalSeats <= 0) {
    console.error('Invalid selection:', selection); // Log error
    return;
  }
  
  console.log('totalSeats',totalSeats)

  // Calculate fees and add them to the selection
  const convenienceFee = calculateConvenienceFee(totalPrice,totalSeats);
  const salesTax = calculateSalesTax(convenienceFee);
  const processingFee = calculateProcessingFee(totalPrice + convenienceFee);
  const totalWithFees = calculateTotal(totalPrice + convenienceFee + salesTax + processingFee);

  setSelectedSeats((prevSeats) => {
    const updatedSeats = {
    ...prevSeats,
    [tableId]: {
    tableId,
    tableNumber,
    tableType,
    price,
    quantity: totalSeats,
    kidSeats: kidSeats || 0,
    convenienceFee,
    salesTax,
    processingFee,
    totalPrice,
    totalAmount: totalWithFees, // Subtotal stored explicitly
    totalWithFees, // Add totalWithFees for this table
    },
    };
    //console.log('Updated SelectedSeats:', updatedSeats); // Debugging log
    return updatedSeats;
    });
  };
  
  // Memoized calculations for pricing
  const {
  totalAmount,
  convenienceFee,
  processingFee,
  salesTax,
  totalWithFees,
  } = useMemo(() => {
  // Ensure selectedSeats has the correct structure
  
  console.log('SelectedSeats for calculations:', selectedSeats); // Debugging log

  const subtotal = Object.values(selectedSeats).reduce((acc, { totalPrice }) => {
  return acc + (totalPrice || 0); // Handle undefined totalPrice gracefully
  }, 0);

  const totalSelectedSeats = Object.values(selectedSeats).reduce((acc, { quantity }) => {
  return acc + (quantity || 0); // Handle undefined totalSeats gracefully
  }, 0);

  console.log('Calculated Subtotal:', subtotal); // Debugging log
  console.log('Calculated Subtotal:', subtotal); // Debugging log
  
  const calculatedConvenienceFee = calculateConvenienceFee(subtotal,totalSelectedSeats);
  const calculatedSalesTax = calculateSalesTax(calculatedConvenienceFee);
  const calculatedProcessingFee = calculateProcessingFee(
  subtotal + calculatedConvenienceFee
  );
  
  const total = calculateTotal(
  subtotal + calculatedConvenienceFee + calculatedProcessingFee + calculatedSalesTax
  );
  
  //console.log({
  //subtotal,
  //calculatedConvenienceFee,
  //calculatedSalesTax,
  //calculatedProcessingFee,
  //total,
  //}); // Debugging log
  
  return {
  totalAmount: subtotal,
  convenienceFee: calculatedConvenienceFee,
  processingFee: calculatedProcessingFee,
  salesTax: calculatedSalesTax,
  totalWithFees: total,
  };
  }, [selectedSeats]);
  
  // Memoized legend items
  const legendItems = useMemo(() => {
    return [
      ...new Map(
        tableOptions
          .filter(
            (item) =>
              item.PricePerSeat !== null &&
              item.PricePerSeat !== undefined &&
              item.AvailableSeats > 0 &&
              item.ElementType !== 'Dance Floor' &&
              item.ElementType !== 'Stage'
          )
          .map((item) => [
            `${item.TableType || item.ElementType}-${item.ElementColor || ''}-${item.PricePerSeat}`,
            {
              type: item.TableType || item.ElementType,
              color:
                item.ElementColor ||
                (item.TableType === 'VIP' ? '#ffd700' : '#007bff'),
              price: item.PricePerSeat,
            },
          ])
      ).values(),
    ];
  }, [tableOptions]);

  const handlePurchase = () => {
    const selectedTickets = { ...selectedSeats }; // Ensure this is a copy of selectedSeats

    //console.log('selectedSeats',selectedSeats)
    //console.log('selectedTickets',selectedTickets)
    //console.log('totalAmount',totalAmount)
    if (Object.keys(selectedSeats).length === 0 || totalAmount === 0) {
      setShowAlert(true);
    } else {
      navigate('/Checkout', {
        state: { event, 
                 selectionType: 'banquetHall', 
                 selectedTickets, // Explicitly pass the selectedTickets structure.
                 selectedSeats,   // Explicitly pass the selectedTickets structure.
                 totalWithFees, 
                 totalAmount
               },
      });
    }
  };

  return (
    <Container className="mt-3">
      <h5 className="text-left mb-3">Select a table to proceed.</h5>
      {showAlert && <Alert variant="danger">Please select at least one table to proceed.</Alert>}

      {/* Legend */}
      <LegendDisplay legendItems={legendItems} />
      
      {/* Banquet Hall Layout */}
      <BanquetHallLayout tableOptions={tableOptions} handleSeatSelection={handleSeatSelection} />
      
      {/* Selected Information */}
      <SelectedTables selectedSeats={selectedSeats} tableOptions={tableOptions} />

      {/* Pricing Summary */}
      <PricingSummary
        totalAmount={totalAmount}
        convenienceFee={convenienceFee}
        processingFee={processingFee}
        salesTax={salesTax}
        totalWithFees={totalWithFees}
      />

      {/* Summary and purchase */}
      <div className="mt-3">
        <Button variant="primary" onClick={handlePurchase} disabled={totalWithFees === 0}>
          Proceed to Purchase
        </Button>
      </div>
    </Container>
  );
};

export default BanquetTableMap;