// eventConfig.js

import GeneralAdmissionPurchase from './GeneralAdmissionPurchase';
import BanquetHallEvent from './BanquetHallEvent';
import ConcertPurchaseSettings from './ConcertPurchaseSettings';

import saveGeneralAdmissionData from './utils/saveGeneralAdmissionData';
import saveBanquetHallData from './utils/saveBanquetHallData';

const eventConfig = {
  "general admission": {
    component: GeneralAdmissionPurchase,
    checkoutSteps: ["Ticket Selection", "Payment", "Confirmation"],
    emailTemplate: "generalAdmissionEmailTemplate",
    saveDataToDB: saveGeneralAdmissionData,
  },
  "banquet hall": {
    component: BanquetHallEvent,
    checkoutSteps: ["Table Selection", "Payment", "Confirmation"],
    emailTemplate: "banquetHallEmailTemplate",
    //saveDataToDB: saveBanquetHallData,
  },
  "concert": {
    component: ConcertPurchaseSettings,
    checkoutSteps: ["Seat Selection", "Payment", "Confirmation"],
    emailTemplate: "concertEmailTemplate",
    //saveDataToDB: saveConcertData,
  },
  "conference": {
    info: "Purchase settings for Conferences include full-access passes and session selections.",
  },
  "festival": {
    info: "Purchase settings for Festivals include day passes and VIP options.",
  },
  // Add other event configurations...
};

export default eventConfig;