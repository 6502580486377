// EventInformation.js

import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import eventConfig from './eventConfig'; // Import the configuration file

const EventInformationPage = () => {
  const location = useLocation();
  const event = location.state?.event || {};

  // Check if the event type is valid and normalize
  const normalizedEventType = event.EventType?.toLowerCase().trim();
  const eventTypeConfig = eventConfig[normalizedEventType];

  const renderPurchaseSettings = () => {
    if (!eventTypeConfig) {
      return (
        <p>
          Purchase settings are not available for this event type. 
          Please contact EventTop Support at 4389242364.
        </p>
      );
    }

    if (eventTypeConfig.component) {
      const Component = eventTypeConfig.component;
      return <Component event={event} />;
    }

    if (eventTypeConfig.info) {
      return <p>{eventTypeConfig.info}</p>;
    }

    return (
      <p>
        Purchase settings are not available for this event type. 
        Please contact EventTop Support at 4389242364.
      </p>
    );
  };

  // Check for critical missing data (e.g., EventName, EventDate)
  if (!event.EventType || !event.EventName || !event.EventDate) {
    return (
      <div>
        <p>Event details are incomplete or unavailable. Please contact support for assistance.</p>
      </div>
    );
  }

  return (
    <Row className="justify-content-center p-md-3 mx-auto bg-shadow">
      <Container className="p-2 p-md-3 mb-0 border rounded bg-light">
        <h1 className="text-center">Ticket Purchase</h1>
        <div className="bg-light-gray p-0 rounded">
          <div className="text mt-0">
            <strong>Event Name:</strong> {event.EventName || 'Unknown'}
          </div>
          <div className="text mt-0">
            <strong>Event Date:</strong> {new Date(event.EventDate).toLocaleDateString('en-US')}
          </div>
          <div className="text mt-0">
            <strong>Venue:</strong> {event.VenueName || 'Unknown'}, {event.VenueAddress || 'Unknown'}
          </div>
          <hr />
          {renderPurchaseSettings()}
        </div>
      </Container>
    </Row>
  );
};

export default EventInformationPage;