import React from 'react';
import { Table } from 'react-bootstrap';

const TicketsTable = ({ tickets }) => {
  return (
    <div className="table-responsive">
      <Table striped bordered hover responsive="sm" className="mb-5">
        <thead className="bg-light table-active">
          <tr>
            <th>Event</th>
            <th>Ticket</th>
            <th>Total Paid</th>
            <th>Purchase Date</th>
          </tr>
        </thead>
        <tbody>
          {tickets.map((ticket, index) => (
            <tr key={ticket.id} className={index === 0 ? 'bg-primary text-white' : ''}>
              <td>{ticket.EventName}</td>
              <td>{ticket.TicketType}</td>
              <td>{ticket.TotalAmount.toFixed(2)}</td>
              <td>
              {new Intl.DateTimeFormat('en-US', {
                dateStyle: 'short',
              }).format(new Date(ticket.PurchaseDate))}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TicketsTable;