import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const TaxInformation = () => {
  const navigate = useNavigate();

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={10}>
          <Card className="shadow-lg border-0 rounded-3">
            <Card.Body className="p-5">
              <div className="text-center mb-4">
                <h1 className="fw-bold">Tax Information</h1>
                <p className="text-muted">
                  Gain a clear understanding of how taxes on ticketing fees are handled and ensure compliance with local regulations.
                </p>
              </div>
              <hr className="my-4" />

              {/* Tax Calculation and Remittance */}
              <section className="mt-4">
                <h2 className="fw-bold text-primary">Tax Calculation and Remittance</h2>
                <p className="text-muted">
                  Tax rates and regulations vary significantly across states, provinces, counties, and local jurisdictions. Taxes on ticketing fees are typically calculated by applying the appropriate jurisdictional tax rate to the ticketing fees charged for your event.
                </p>
                <p className="text-muted">
                  Taxes are determined based on the ZIP or postal code of your event's location. It is essential to ensure that the event location is accurately provided to guarantee correct tax calculations and compliance.
                </p>
              </section>

              {/* Tax Remittance Schedule */}
              <section className="mt-4">
                <h2 className="fw-bold text-primary">Tax Remittance Schedule</h2>
                <p className="text-muted">
                  Taxes collected are remitted to the relevant tax authorities on a schedule determined by the jurisdiction. This schedule can range from bi-monthly, monthly, or quarterly, to semi-annual or annual remittances. EventTop.ca ensures timely submission of all taxes collected on your behalf.
                </p>
              </section>

              {/* Transactions Explained */}
              <section className="mt-4">
                <h2 className="fw-bold text-primary">Understanding Transactions on EventTop.ca</h2>
                <div className="mb-3">
                  <h3 className="fw-semibold">1. Between You and EventTop.ca</h3>
                  <p className="text-muted">
                    The service fees charged by EventTop.ca are for the services provided to you, the event organizer, and not the attendees. These fees are deducted from your payout and are reflected in your invoices. EventTop.ca is responsible for collecting, remitting, and reporting applicable taxes on these service fees.
                  </p>
                </div>
                <div>
                  <h3 className="fw-semibold">2. Between You and the Attendee</h3>
                  <p className="text-muted">
                    If you choose to pass the service fees onto your attendees, these fees are added to the ticket price. In this case, you are responsible for collecting, reporting, and remitting the appropriate tax on the full ticket price, including the service fees, to the relevant tax authorities.
                  </p>
                </div>
              </section>

              {/* Impact of Tax on Payouts */}
              <section className="mt-4">
                <h2 className="fw-bold text-primary">Impact of Tax on Your Payouts</h2>
                <p className="text-muted">
                  Your payouts are influenced by how service fees and their associated taxes are configured:
                </p>
                <ul className="text-muted">
                  <li>
                    <strong>If fees are passed to attendees:</strong> The taxes are also passed to attendees, and your payouts remain unaffected. The tax is added on top of the ticket price and paid by the attendees.
                  </li>
                  <li>
                    <strong>If fees are absorbed by you:</strong> Taxes on the service fees are also absorbed, reducing your overall payout since attendees are not paying for these amounts.
                  </li>
                </ul>
              </section>

              {/* Changing Fee Responsibility */}
              <section className="mt-4">
                <h2 className="fw-bold text-primary">Changing Who Pays the Fees</h2>
                <p className="text-muted">
                  You can configure the responsibility for service fees and their taxes:
                </p>
                <ul className="text-muted">
                  <li>
                    <strong>Default setting:</strong> Pass the service fees and their associated taxes to attendees.
                  </li>
                  <li>
                    <strong>Alternative setting:</strong> Absorb the service fees and associated taxes yourself.
                  </li>
                </ul>
                <p className="text-muted">
                  Please note that any changes to this setting after the event has started will only apply to future ticket sales. Transactions completed before the change will retain the previous structure.
                </p>
              </section>

              {/* CTA */}
              <div className="text-center mt-5">
                <Button
                  variant="primary"
                  size="lg"
                  onClick={() => navigate('/create-event')}
                >
                  Learn More About Tax Management
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TaxInformation;