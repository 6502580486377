import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import OnlineAdvertisingBenefits from '../assets/OnlineAdvertisingBenefits.png';
import OnlineAdvertisingBenefits2 from '../assets/OnlineAdvertisingBenefits2.png';

const AdvertisingInfo = () => {
  const handleContact = () => {
    // Logic to open a contact form or navigate to a contact page
    window.open('/contact-us', '_blank');
  };

  return (
    
    <Row className="justify-content-center p-md-3 mx-auto bg-shadow">

    
    <Container className="p-2 p-md-3 mb-0 border rounded bg-light">
    
      <h1 className="text-center mb-4" style={{ color: '#333', fontWeight: 'bold' }}>Advertise with EventTop</h1>
      <p className="text-center mb-5" style={{ color: '#666', fontSize: '1.1rem' }}>
        Maximize your event’s reach by advertising with us! Reach a vibrant, engaged audience directly on the EventTop platform. Here’s everything you need to know.
      </p>
      
      {/* Introductory Image */}
      <Row className="justify-content-center mb-5 ">
        <Col md={12}>
        <img 
          src={OnlineAdvertisingBenefits2} 
          alt="Online Advertising Benefits" 
          className="img-fluid shadow-lg w-100" 
          style={{
            borderRadius: '15px', // Increase rounding for smoother corners
            boxShadow: '0px 10px 30px rgba(0, 0, 0.3, 0.5)', // Add a deep shadow effect
          }}
        />
        </Col>
      </Row>

      {/* Benefits Section */}
      <Row className="justify-content-center mb-5">
        <Col md={4} className="mb-3">
          <Card className="shadow-sm p-4 h-100 d-flex flex-column justify-content-between" style={{ borderRadius: '12px', background: 'linear-gradient(135deg, #e0f7fa, #b2ebf2)' }}>
            <Card.Body>
              <h4 style={{ fontWeight: 'bold', color: '#00796b' }}>Why Advertise on EventTop?</h4>
              <p style={{ color: '#333' }}>
                EventTop provides a unique opportunity to connect with attendees and event enthusiasts, offering your business premium visibility to a highly targeted audience.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-3">
          <Card className="shadow-sm p-4 h-100 d-flex flex-column justify-content-between" style={{ borderRadius: '12px', background: 'linear-gradient(135deg, #ffe0b2, #ffcc80)' }}>
            <Card.Body>
              <h4 style={{ fontWeight: 'bold', color: '#f57c00' }}>Who Sees Your Ad?</h4>
              <p style={{ color: '#333' }}>
                Our platform is frequented by event-goers, business professionals, and organizers looking to connect. Your ad will be seen by people ready to explore and engage.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-3">
          <Card className="shadow-sm p-4 h-100 d-flex flex-column justify-content-between" style={{ borderRadius: '12px', background: 'linear-gradient(135deg, #d1c4e9, #b39ddb)' }}>
            <Card.Body>
              <h4 style={{ fontWeight: 'bold', color: '#5e35b1' }}>What We Offer</h4>
              <p style={{ color: '#333' }}>
                We provide tailored ad placements directly on event pages, during ticket purchases, and on our main homepage to maximize exposure for your brand.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Ad Options Section with uniform height for each small block */}
      <h3 className="text-center mb-4" style={{ color: '#333', fontWeight: 'bold' }}>Ad Placement Options</h3>
      <Row className="justify-content-center mb-5">
        <Col md={4} className="mb-3">
          <Card className="shadow-sm p-4 h-100 d-flex flex-column justify-content-between" style={{ borderRadius: '12px', backgroundColor: '#ffffff' }}>
            <Card.Body>
              <h5 style={{ color: '#00796b', fontWeight: 'bold' }}>Event Page Banner</h5>
              <p style={{ color: '#666' }}>
                Showcase your ad prominently at the top of event pages. Ideal for capturing attention from event-goers looking for event details.
              </p>
              <p><center><strong>Cost:</strong> $100 / week</center></p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-3">
          <Card className="shadow-sm p-4 h-100 d-flex flex-column justify-content-between" style={{ borderRadius: '12px', backgroundColor: '#ffffff' }}>
            <Card.Body>
              <h5 style={{ color: '#f57c00', fontWeight: 'bold' }}>Ticket Purchase Spotlight</h5>
              <p style={{ color: '#666' }}>
                Display your ad near the purchase button to reach customers making buying decisions. Perfect for businesses targeting active consumers.
              </p>
              <p><center><strong>Cost:</strong> $150 / week</center></p>
              </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-3">
          <Card className="shadow-sm p-4 h-100 d-flex flex-column justify-content-between" style={{ borderRadius: '12px', backgroundColor: '#ffffff' }}>
            <Card.Body>
              <h5 style={{ color: '#5e35b1', fontWeight: 'bold' }}>Homepage Carousel</h5>
              <p style={{ color: '#666' }}>
                Place your ad in the homepage carousel for maximum visibility to all visitors. A premium option for broad exposure.
              </p>
              <p><center><strong>Cost:</strong> $200 / week</center></p>    
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Ad Options Section */}
      <h3 className="text-center mb-4" style={{ color: '#333', fontWeight: 'bold' }}>Ad Placement Options</h3>
      <Row className="justify-content-center mb-5">
        <Col md={4} className="mb-3">
          <Card className="shadow-sm p-4 h-100 d-flex flex-column justify-content-between" style={{ borderRadius: '12px', backgroundColor: '#ffffff' }}>
            <Card.Body>
              <h5 style={{ color: '#00796b', fontWeight: 'bold' }}>Event Page Banner</h5>
              <p style={{ color: '#666' }}>
                Showcase your ad prominently at the top of event pages. Ideal for capturing attention from event-goers looking for event details.
              </p>
              <p><center><strong>Cost:</strong> $100 / week</center></p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-3">
          <Card className="shadow-sm p-4 h-100 d-flex flex-column justify-content-between" style={{ borderRadius: '12px', backgroundColor: '#ffffff' }}>
            <Card.Body>
              <h5 style={{ color: '#f57c00', fontWeight: 'bold' }}>Ticket Purchase Spotlight</h5>
              <p style={{ color: '#666' }}>
                Display your ad near the purchase button to reach customers making buying decisions. Perfect for businesses targeting active consumers.
              </p>
              <p><center><strong>Cost:</strong> $150 / week</center></p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-3">
          <Card className="shadow-sm p-4 h-100 d-flex flex-column justify-content-between" style={{ borderRadius: '12px', backgroundColor: '#ffffff' }}>
            <Card.Body>
              <h5 style={{ color: '#5e35b1', fontWeight: 'bold' }}>Homepage Carousel</h5>
              <p style={{ color: '#666' }}>
                Place your ad in the homepage carousel for maximum visibility to all visitors. A premium option for broad exposure.
              </p>
              <p><center><strong>Cost:</strong> $200 / week</center></p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Target Audience Section */}
      <h3 className="text-center mb-4" style={{ color: '#333', fontWeight: 'bold' }}>Target Audience</h3>
      <p className="text-center mb-5" style={{ color: '#666', fontSize: '1.1rem' }}>
        EventTop users are active event-goers, ranging from concert lovers and food enthusiasts to corporate attendees and community organizers. With our targeted placements, your ad reaches a diverse yet focused audience ready to engage.
      </p>
      
      {/* Introductory Image */}
      <Row className="justify-content-center mb-5 ">
        <Col md={12}>
        <img 
          src={OnlineAdvertisingBenefits} 
          alt="Online Advertising Benefits" 
          className="img-fluid shadow-lg" 
          style={{
            borderRadius: '15px', // Increase rounding for smoother corners
            boxShadow: '0px 10px 30px rgba(0, 0, 0.3, 0.5)', // Add a deep shadow effect
          }}
        />
        </Col>
      </Row>

      {/* Pricing & Duration Section */}
      <h3 className="text-center mb-4" style={{ color: '#333', fontWeight: 'bold' }}>Flexible Pricing and Duration</h3>
      <p className="text-center mb-5" style={{ color: '#666', fontSize: '1.1rem' }}>
        Choose a plan that fits your budget and timeline. Options start from a one-week commitment, with discounts available for extended placements.
      </p>

      <Row className="justify-content-center">
        <Col md={4}>
          <Card className="shadow-sm p-4 mb-3" style={{ borderRadius: '12px', backgroundColor: '#ffffff' }}>
            <Card.Body className="text-center">
              <h5 style={{ fontWeight: 'bold', color: '#333' }}>Weekly Plan</h5>
              <p style={{ color: '#666' }}>$100 - $200 depending on placement</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="shadow-sm p-4 mb-3" style={{ borderRadius: '12px', backgroundColor: '#ffffff' }}>
            <Card.Body className="text-center">
              <h5 style={{ fontWeight: 'bold', color: '#333' }}>Monthly Plan</h5>
              <p style={{ color: '#666' }}>20% discount for 4+ weeks commitment</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="shadow-sm p-4 mb-3" style={{ borderRadius: '12px', backgroundColor: '#ffffff' }}>
            <Card.Body className="text-center">
              <h5 style={{ fontWeight: 'bold', color: '#333' }}>Quarterly Plan</h5>
              <p style={{ color: '#666' }}>30% discount for 12+ weeks commitment</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Contact CTA */}
      <div className="text-center mt-5">
        <Button onClick={handleContact} variant="primary" style={{
          padding: '10px 20px',
          fontSize: '1.1rem',
          fontWeight: 'bold',
          borderRadius: '25px',
        }}>
          Contact Us to Get Started
        </Button>
      </div>
    </Container>
    
    </Row>
  );
};

export default AdvertisingInfo;