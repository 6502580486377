// src/utils/convenienceFeeCalculator.js

// Default values
const DEFAULT_CONVENIENCE_FEE_RATE = 0.030; // 3% convenience fee rate
const DEFAULT_FIXED = 1.3; // $1.30 fixed fee for events with maps

// Alternative values for general admission
const GENERAL_ADMISSION_CONVENIENCE_FEE_RATE = 0.0175; // 1.75% convenience fee rate
const GENERAL_ADMISSION_FIXED = 0.65; // $0.65 fixed fee

// Function to calculate convenience fee for a given amount
function calculateConvenienceFee(amount, totalSeats, selectionType = 'default') {
  const isGeneralAdmission = selectionType === 'generalAdmission';
  const CONVENIENCE_FEE_RATE = isGeneralAdmission ? GENERAL_ADMISSION_CONVENIENCE_FEE_RATE : DEFAULT_CONVENIENCE_FEE_RATE;
  const FIXED = isGeneralAdmission ? GENERAL_ADMISSION_FIXED : DEFAULT_FIXED;

  return amount * CONVENIENCE_FEE_RATE + FIXED * totalSeats;
}

// Export the functions
export { calculateConvenienceFee };