// src/services/auth.js

import { API_ENDPOINTS } from '../config';

export const refreshAccessToken = async () => {
  try {
    //const response = await fetch('http://localhost:3010/refresh-token', {
    const response = await fetch(API_ENDPOINTS.refreshToken, {
      method: 'POST',
      credentials: 'include' // Include cookies in the request
    });

    if (!response.ok) {
      throw new Error('Token refresh failed');
    }

    const { accessToken } = await response.json();
    localStorage.setItem('accessToken', accessToken);
    return accessToken;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};


// src/services/auth.js

export const fetchWithAuth = async (url, options = {}) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        'Authorization': `Bearer ${accessToken}`
      }
    });
    
    if (response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const retryResponse = await fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          'Authorization': `Bearer ${newAccessToken}`
        }
      });
      return retryResponse;
    }
    
    return response;
  } catch (error) {
    console.error('Error fetching with auth:', error);
    throw error;
  }
};

