// ContactUs.js

import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const ContactUsPage = () => {
  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={12}>
          <Card className="shadow-lg">
            <Card.Body>
              <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
                <main className="px-3">
                  <h1>Contact Us!</h1>

                  <div className="float p-3 border rounded bg-light">
                    <p className="lead">
                      <span role="img" aria-label="Globe">🌐</span> Welcome to EventTop! <span role="img" aria-label="Party">🎉</span> We are a premier online ticketing platform based in Canada, dedicated to making global celebrations accessible at your fingertips.
                    </p>
                    <p className="lead">
                      <span role="img" aria-label="Earth">🌍</span> Our mission is to promote and connect communities, especially events highlighting the diverse cultures of Azerbaijani, Turkish, and Turkic communities worldwide.
                    </p>
                  </div>

                  <div className="float p-3 mt-1 border rounded bg-light">
                    <p className="lead">
                      <span role="img" aria-label="Theater masks">🎭</span> Toronto, ON, Canada
                    </p>
                    <p className="lead">
                      <span role="img" aria-label="Email">📧</span> Contact email: 
                      <a href="mailto:Info@EventTop.ca" className="email">
                        &#73;&#110;&#102;&#111;&#64;&#69;&#118;&#101;&#110;&#116;&#84;&#111;&#112;&#46;&#99;&#97;
                      </a>
                    </p>
                    <p className="lead">
                      <span role="img" aria-label="Phone">📞</span> Contact phone: 438-924-2364 (9 AM - 5:30 PM EST)
                    </p>
                  </div>

                  <div className="float p-3 mt-1 border rounded bg-light">
                    <h2 className="lead text-center" style={{ fontSize: "2em" }}>Have Questions About Your Tickets?</h2>
                    <p>If you have questions about your tickets, we’re here to help! In most cases, you can contact the event organizer directly for details about your purchase and event-specific inquiries.</p>
                    <p>Alternatively, you can reach out to our EventTop support team:</p>
                    <ul>
                      <li><strong>Call:</strong> 438-924-2364 (available 9 AM - 5:30 PM EST)</li>
                      <li><strong>Email:</strong> <a href="mailto:Info@EventTop.ca">Info@EventTop.ca</a></li>
                    </ul>
                    <p>Our team is committed to assisting you with any concerns and ensuring a seamless event experience.</p>
                  </div>

                  <div className="float p-3 mt-1 border rounded bg-light">
                    <h2 className="lead text-center" style={{ fontSize: "2em" }}>Hosting and Creating Events on EventTop</h2>
                    <p>
                      <span role="img" aria-label="Calendar">📅</span> Hosting or creating an event on EventTop is easy and effective! We provide a complete suite of tools to help you plan, promote, and manage your event successfully.
                    </p>
                    <p>
                      <span role="img" aria-label="Blue diamond">🔹</span> <strong>Quick Setup:</strong> Start your event in minutes with our user-friendly platform.
                    </p>
                    <p>
                      <span role="img" aria-label="Blue diamond">🔹</span> <strong>Customizable Options:</strong> Customize your event page to reflect its unique essence.
                    </p>
                    <p>
                      <span role="img" aria-label="Blue diamond">🔹</span> <strong>Integrated Marketing:</strong> Boost visibility with our built-in marketing tools.
                    </p>
                    <p>
                      <span role="img" aria-label="Blue diamond">🔹</span> <strong>Comprehensive Analytics:</strong> Monitor your event's performance with real-time data insights.
                    </p>
                    <p>Ready to begin? Visit our <a href="/EventHostingPage" className="text-primary">Event Hosting</a> page to get started!</p>
                  </div>

                  <div className="float p-3 mt-1 border rounded bg-light">
                    <h2 className="lead text-center" style={{ fontSize: "2em" }}>EventTop Motto in Different Languages</h2>
                    <ul className="list-unstyled">
                      {/* Motto translations */}
                      <li><span className="fw-bold">Turkish:</span> "Event'ini Top, Ticket'ini Al"</li>
                      <li><span className="fw-bold">Spanish:</span> "Encuentra tu evento, obtén tu entrada"</li>
                      <li><span className="fw-bold">English:</span> "Find your event, get your ticket"</li>
                      <li><span className="fw-bold">French:</span> "Trouvez votre événement, achetez votre billet"</li>
                      <li><span className="fw-bold">German:</span> "Finde dein Event, hol dir dein Ticket"</li>
                      <li><span className="fw-bold">Italian:</span> "Trova il tuo evento, prendi il tuo biglietto"</li>
                      <li><span className="fw-bold">Portuguese:</span> "Encontre o seu evento, compre o seu ingresso"</li>
                      <li><span className="fw-bold">Persian:</span> "رویداد خود را پیدا کنید، بلیط خود را بگیرید"</li>
                      <li><span className="fw-bold">Azerbaijani:</span> "Tədbirinizi tapın, biletinizi alın"</li>
                      <li><span className="fw-bold">Russian:</span> "Найдите свое мероприятие, получите билет"</li>
                      <li><span className="fw-bold">Arabic:</span> "ابحث عن حدثك ، احصل على تذكرتك"</li>
                      <li><span className="fw-bold">Chinese (Simplified):</span> "找到您的活动，购买您的门票"</li>
                      <li><span className="fw-bold">Japanese:</span> "あなたのイベントを見つけて、あなたのチケットを手に入れてください"</li>
                      <li><span className="fw-bold">Hindi:</span> "अपनी घटना ढूंढें, अपना टिकट प्राप्त करें"</li>
                    </ul>
                  </div>
                </main>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactUsPage;