// SpotlightAd.js

import React from 'react';
import { Container, Row, Card, Button } from 'react-bootstrap';
import { FaBullhorn } from 'react-icons/fa';

const SpotlightAd = () => {
  const handleAdLearnMore = () => {
    // Logic for navigating to the advertising information page
    window.open('/advertising-info', '_blank');
  };
  
  return (
    <Row className="justify-content-center p-2 mx-auto bg-shadow">
      <Container className="p-3 mb-0 rounded" style={{
        background: 'linear-gradient(135deg, #4b6cb7 0%, #182848 100%)', // Subtle dark blue gradient for better contrast
        borderRadius: '12px',
        color: '#f1f1f1', // Light color for readability
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        textAlign: 'center'
      }}>
        <Card.Body>
          <FaBullhorn size={50} color="#ffdd57" style={{ marginBottom: '10px' }} /> {/* Icon with a bright color */}
          <Card.Title style={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#ffffff' }}>Promote Your Business Here!</Card.Title>
          <Card.Text style={{ fontSize: '1rem', marginBottom: '15px', color: '#e1e1e1' }}>
            Reach more customers by advertising on EventTop! Showcase your event right here to attract more visitors.
          </Card.Text>
          <Button variant="light" onClick={handleAdLearnMore} style={{
            color: '#4b6cb7',
            fontWeight: 'bold',
            border: 'none',
            padding: '10px 20px',
            borderRadius: '20px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
          }}>
            Learn More
          </Button>
        </Card.Body>
      </Container>
    </Row>
  );
};

export default SpotlightAd;