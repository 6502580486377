// UserBadge.js

//import React from 'react';
//import { Link, useNavigate } from 'react-router-dom';
//
//const UserBadge = ({ user, onSignOut }) => {
//const navigate = useNavigate();
//
//const handleSignOut = () => {
//  if (typeof onSignOut === 'function') {
//    onSignOut();
//  }
//  navigate('/sign-in'); // Navigate to the sign-in page
//};
//
//return (
//  <div style={{ position: 'absolute', top: 20, padding: '0px', right: '0px' }}>
//  {user ? (
//    <div className="text-info d-flex align-items-right">
//    <Link to="/userpanel" className="btn btn-link me-0"><strong>Welcome, {user.firstname}!</strong></Link>
//    <button className="btn btn-link" onClick={handleSignOut}>Sign Out</button>
//    </div>
//  ) : (
//    <span className="text-info align-middle">
//    </span>
//  )}
//  </div>
//);
//};
//
//export default UserBadge;

//import React from 'react';
//import { Link, useNavigate } from 'react-router-dom';
//
//const UserBadge = ({ user, onSignOut }) => {
//const navigate = useNavigate();
//
////useEffect(() => {
//  //console.log('UserBadge received updated user:', user);
////}, [user]);
//
//const handleSignOut = () => {
//  if (typeof onSignOut === 'function') {
//    onSignOut();
//  }
//  navigate('/sign-in');
//};
//
//
//
//return (
//  
//  <div style={{ position: 'absolute', top: 20, padding: '0px', right: '0px' }}>
//  {user ? (
//    <div className="text-info d-flex align-items-center">
//    <Link to="/userpanel" className="btn btn-link me-0">
//    <strong>Welcome, {user.firstname}!</strong>
//    </Link>
//    <button className="btn btn-link" onClick={handleSignOut}>
//    Sign Out
//    </button>
//    </div>
//  ) : (
//    <Link to="/sign-in" className="btn btn-link text-info">
//    Sign In/Register
//    </Link>
//  )}
//  </div>
//);
//};
//
//export default UserBadge;

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const UserBadge = ({ user, onSignOut }) => {
  const navigate = useNavigate();
  
  const handleSignOut = () => {
    if (typeof onSignOut === 'function') {
      onSignOut();
    }
    navigate('/sign-in'); // Redirect to sign-in page
  };
  
  return (
    <div className="user-badge d-flex align-items-center">
    {user ? (
      <>
      <Link to="/userpanel" className="btn btn-link me-0">
        <strong>Welcome, {user.firstname}!</strong>
      </Link>
      <button className="btn btn-link sign-out-button" onClick={handleSignOut}>
      Sign Out
      </button>
      </>
    ) : (
      <Link to="/sign-in" className="btn btn-link sign-in-link">
      Sign In/Register
      </Link>
    )}
    </div>
  );
};

export default UserBadge;
  