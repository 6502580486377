import React from 'react';
import { Button, Row, Col, Card, Image, Alert, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './PromoBanner.css';
import placeholderImage3 from '../assets/OrganizerTicketAnalytics.png';

const PromoBanner = () => {
  const navigate = useNavigate();

  const handleCreateEvent = () => {
    navigate('/EventHostingPage');
  };

  const handleEventOrganizationInstructions = () => {
    navigate('/event-organization-instructions');
  };

  return (
    <>
      <div className="promo-banner">
        <Row className="align-items-center">
          <Col md={6} className="promo-text">
            <h1>Sell tickets online with the leading event booking system</h1>
            <p>
              For all your event ticketing needs across Canada and the United States, from intimate gatherings to large-scale festivals, our advanced booking system provides seamless solutions tailored to meet the unique demands of any event.
            </p>
            <div className="promo-buttons">
              <Button variant="warning" className="create-event-btn" onClick={handleCreateEvent}>
                Create Event
              </Button>
              <Button variant="outline-primary" className="event-org-btn" onClick={handleEventOrganizationInstructions}>
                Event Organization Instructions
              </Button>
            </div>
          </Col>
          <Col md={6} className="promo-cards">
            <div className="d-flex justify-content-center my-4">
              <Image 
                src={placeholderImage3} 
                alt="Example Organizer Control Panel" 
                fluid 
                className="border rounded shadow-lg p-2"
                style={{ maxWidth: '90%', borderColor: '#ddd', borderWidth: '10px' }}
              />
            </div>
          </Col>
        </Row>
      </div>

      <Container className="free-event-banner">
        <Alert style={{ backgroundColor: '#e0ffe0', paddingLeft: '1rem', paddingRight: '1rem' }} className="d-flex mt-4 align-items-center border rounded">
          <Row className="w-100">
            <Col md={8} className="text-center text-md-start">
              <h2><strong>Did you know? Posting free events is absolutely free!</strong></h2>
            </Col>
            <Col md={4} className="text-center text-md-end">
              <Button variant="outline-primary" className="rounded" onClick={handleCreateEvent}>
                Create Event
              </Button>
            </Col>
          </Row>
        </Alert>
      </Container>
    </>
  );
};

export default PromoBanner;