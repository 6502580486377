import React from 'react';
import PropTypes from 'prop-types';

const LegendDisplay = ({ legendItems }) => {
  if (!legendItems || legendItems.length === 0) {
    return <p>No legend items available.</p>;
  }

  return (
    <div
      className="mt-1 mb-1"
      style={{
        border: '2px solid #ccc',
        borderRadius: '10px',
        padding: '1rem',
        textAlign: 'center',
        backgroundColor: '#f8f9fa',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '2rem',
          flexWrap: 'wrap',
        }}
      >
        {legendItems.map((legendItem, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.4rem',
            }}
          >
            <div
              style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                backgroundColor: legendItem.color,
              }}
            ></div>
            <span>${legendItem.price.toFixed(2)} per seat</span>
          </div>
        ))}
      </div>
    </div>
  );
};

// PropTypes to validate the legendItems prop
LegendDisplay.propTypes = {
  legendItems: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default LegendDisplay;