import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Carousel, Image, Button, Table, Badge } from 'react-bootstrap';
import eventHostingImage1 from '../assets/event-hosting1.jpg';
import eventHostingImage2 from '../assets/event-hosting2.jpg';
import eventHostingImage3 from '../assets/event-hosting3.jpg';
import eventHostingImage4 from '../assets/event-hosting4.jpg';
import eventHostingImage5 from '../assets/event-hosting5.jpg';
import eventHostingImage6 from '../assets/event-hosting6.jpg';
import eventHostingImage7 from '../assets/event-hosting7.jpg';
import eventHostingImage8 from '../assets/event-hosting8.jpg';
import './EventHostingPage.css'; // Import custom CSS if needed
import './PricingComparisonTable.css'; // Import custom CSS
import TicketPriceCalculator from './TicketPriceCalculator';
import PromotionalServices from './PromotionalServices';

const PricingComparisonTable = () => (
  <Table striped bordered hover responsive className="mt-4">
  <thead>
  <tr>
  <th>Platform</th>
  <th>Free Events</th>
  <th>Ticketed Events (Service Fee)</th>
  <th>Additional Features</th>
  </tr>
  </thead>
  <tbody>
  <tr>
  <td>
  <strong style={{ fontSize: '1.2em', color: '#007bff' }}>EventTop</strong>
  </td>
  <td>
  <Badge bg="success" className="text-white" style={{ fontSize: '1.1em' }}>
  Free
  </Badge>
  </td>
  <td className="text-success glow-bounce" style={{ fontSize: '1.1em', color: '#007bff' }}>
  <strong>1.75% + C$0.65 per ticket</strong>
  </td>
  <td style={{ fontSize: '1.1em', color: '#007bff' }}>
  Customizable Pages, Real-Time Analytics, Dedicated Support
  </td>
  </tr>
  <tr>
  <td><strong>Eventbrite</strong></td>
  <td>
  <Badge bg="success" className="text-white">
  Free
  </Badge>
  </td>
  <td className="text-danger">
  <strong>3.5% + C$1.29 per ticket</strong>
  </td>
  <td>Basic Analytics, Email Support</td>
  </tr>
  <tr>
  <td><strong>Ticketmaster</strong></td>
  <td>
  <Badge bg="danger" className="text-white">
  Not Available
  </Badge>
  </td>
  <td className="text-danger">
  <strong>15%–25% of ticket price</strong>
  </td>
  <td>Ticket Resale, Fan Verification, Basic Analytics</td>
  </tr>
  </tbody>
  </Table>
);

const EventHostingPage = () => {
  const navigate = useNavigate();

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={12}>
          <Card className="shadow-lg mb-4">
            <Card.Body>
              <div className="text-center mb-4">
                <h1>Host Your Event with EventTop</h1>
                <p className="lead">Bring your vision to life with our comprehensive event-hosting solutions. From intimate gatherings to grand festivals, we’ve got you covered.</p>
              </div>
              
              <div className="text-center mb-4">
                <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center">
                  <Button 
                    href="/registration-form" 
                    className="btn-lg mb-2 mb-sm-0 me-sm-3" 
                    variant="primary" 
                    style={{ width: '320px', boxShadow: '2px 2px 8px rgba(0, 0, 1, 1.9)' }}
                  >
                    Start Hosting Your Event!
                  </Button>
                  <Button 
                    href="/event-organization-instructions" 
                    className="btn-lg" 
                    variant="secondary" 
                    style={{ width: '320px', boxShadow: '2px 2px 8px rgba(0, 0, 1, 1.9)' }}
                  >
                    Event Organization Instructions
                  </Button>
                </div>
              </div>

              <div className="carousel-wrapper carousel-controls-outside">
                <Carousel className="mx-auto">
                  <Carousel.Item>
                    <Image src={eventHostingImage1} alt="Event Hosting 1" fluid />
                    <Carousel.Caption>
                      <h3>Host Unforgettable Events</h3>
                      <p>Make your event a success with our extensive planning and hosting tools.</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image src={eventHostingImage7} alt="Event Hosting 2" fluid />
                    <Carousel.Caption>
                      <h3>Connect with Your Audience</h3>
                      <p>Reach a wider audience and engage them like never before.</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image src={eventHostingImage3} alt="Event Hosting 3" fluid />
                    <Carousel.Caption>
                      <h3>Streamline Your Event Management</h3>
                      <p>Efficiently manage every aspect of your event from one platform.</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image src={eventHostingImage6} alt="Event Hosting 4" fluid />
                    <Carousel.Caption>
                      <h3>Streamline Your Event Management</h3>
                      <p>Efficiently manage every aspect of your event from one platform.</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image src={eventHostingImage5} alt="Event Hosting 5" fluid />
                    <Carousel.Caption>
                      <h3>Streamline Your Event Management</h3>
                      <p>Efficiently manage every aspect of your event from one platform.</p>
                    </Carousel.Caption>
                    
                    </Carousel.Item>

                  <Carousel.Item>
                    <Image src={eventHostingImage4} alt="Event Hosting 6" fluid />
                    <Carousel.Caption>
                    <h3>Streamline Your Event Management</h3>
                    <p>Efficiently manage every aspect of your event from one platform.</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image src={eventHostingImage7} alt="Event Hosting 7" fluid />
                    <Carousel.Caption>
                    <h3>Streamline Your Event Management</h3>
                    <p>Efficiently manage every aspect of your event from one platform.</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image src={eventHostingImage2} alt="Event Hosting 8" fluid />
                    <Carousel.Caption>
                    <h3>Streamline Your Event Management</h3>
                    <p>Efficiently manage every aspect of your event from one platform.</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                    
                  </Carousel>
              </div>
              



    <div className="mt-5 text-center">
    <h2>Competitive Pricing</h2>
    <p>EventTop provides an affordable, feature-rich platform for event hosting. Our service fees are half of what Eventbrite charges, and we support both free and ticketed events.</p>
    
    <Row className="justify-content-center mt-3">
      <Col md={10}>
        <Card className="p-4 shadow-sm" style={{ backgroundColor: "#f7f7f9", borderRadius: "10px" }}>
        <Row>
        <Col md={6} className="text-center">
        <p className="mb-1 text-primary"><strong>No upfront fees</strong></p>
        <h4 className="fw-bold">Free for organizer, low fees for attendees on paid tickets</h4>
        <Link to="/registration-form">
        <Button variant="primary" size="lg" className="mt-3">
        Get started for free
        </Button>
        </Link>
      </Col>
      <Col md={6}>
        <h5><strong>Our pricing is simple</strong></h5>
        <ul className="list-unstyled mt-3">
        <li>No fees for free events</li>
        <li>1.75% + C$0.65 service fee per ticket</li>
        <li>2.9% payment processing fee per order</li>
        <li>Fees are paid by ticket buyers on paid tickets, unless you choose to absorb them.</li>
        </ul>
        <a href="/pricing-info" className="text-primary">Find out more about how fees work</a>
        </Col>
        </Row>
        </Card>
      </Col>
    </Row>
        
    
    
    {/* Pricing Comparison Table */}
    <PricingComparisonTable />
    
    </div>
    
    <hr></hr>

    <TicketPriceCalculator />
    <PromotionalServices />
        
    <hr></hr>

    <Row className="justify-content-center mt-3">
    <Button
    variant="primary center w-50"
    className="shadow-lg rounded px-4 py-2 mt-3 center "
    onClick={() => navigate('/tax-information')}
    >
    Learn More About Tax Information
    </Button>
    </Row>
    

              <div className="mt-5">
                
    <h2 className="mt-5 mb-3 text-center">Why Choose EventTop for Your Event Hosting?</h2>
    <p className="text-center">EventTop offers a robust platform that simplifies event hosting and enhances the experience for both hosts and attendees. Here’s what you can expect:</p>
    
    <Row className="gy-4">
    <Col md={6}>
    <Card className="h-100 shadow-sm">
    <Card.Body>
    <Card.Title><strong>Seamless Setup</strong></Card.Title>
    <Card.Text>Get your event up and running quickly with our intuitive setup process. Our platform handles the logistics, letting you focus on creating memorable experiences.</Card.Text>
    </Card.Body>
    </Card>
    </Col>
    
    <Col md={6}>
    <Card className="h-100 shadow-sm">
    <Card.Body>
    <Card.Title><strong>End-to-End Support</strong></Card.Title>
    <Card.Text>From ticketing to promotion and analytics, EventTop provides full support at every stage of event management. Our team ensures your event’s success.</Card.Text>
    </Card.Body>
    </Card>
    </Col>
    
    <Col md={6}>
    <Card className="h-100 shadow-sm">
    <Card.Body>
    <Card.Title><strong>Customized Event Pages</strong></Card.Title>
    <Card.Text>Design your event’s page to reflect its unique style. Customize it with branding, themes, and details to make your event stand out.</Card.Text>
    </Card.Body>
    </Card>
    </Col>
    
    <Col md={6}>
    <Card className="h-100 shadow-sm">
    <Card.Body>
    <Card.Title><strong>Advanced Ticketing Solutions</strong></Card.Title>
    <Card.Text>Flexible ticketing options, from VIP access to group discounts, make managing ticket sales easy and efficient on EventTop.</Card.Text>
    </Card.Body>
    </Card>
    </Col>
    
    <Col md={6}>
    <Card className="h-100 shadow-sm">
    <Card.Body>
    <Card.Title><strong>Data-Driven Insights</strong></Card.Title>
    <Card.Text>Real-time analytics tools provide insights into ticket sales, attendee demographics, and engagement, enabling informed decision-making.</Card.Text>
    </Card.Body>
    </Card>
    </Col>
    
    <Col md={6}>
    <Card className="h-100 shadow-sm">
    <Card.Body>
    <Card.Title><strong>Secure Transactions</strong></Card.Title>
    <Card.Text>Our platform prioritizes security, ensuring all transactions are safe and compliant, providing peace of mind for hosts and attendees.</Card.Text>
    </Card.Body>
    </Card>
    </Col>
    
    <Col md={6}>
    <Card className="h-100 shadow-sm">
    <Card.Body>
    <Card.Title><strong>Broad Audience Reach</strong></Card.Title>
    <Card.Text>Hosting on EventTop connects your event to a large network, amplifying reach with targeted campaigns and social media promotions.</Card.Text>
    </Card.Body>
    </Card>
    </Col>
    
    <Col md={6}>
    <Card className="h-100 shadow-sm">
    <Card.Body>
    <Card.Title><strong>Flexible Payment Options</strong></Card.Title>
    <Card.Text>Accept payments from multiple secure methods, enabling attendees from various regions to purchase tickets with ease and confidence.</Card.Text>
    </Card.Body>
    </Card>
    </Col>
    
    <Col md={6}>
    <Card className="h-100 shadow-sm">
    <Card.Body>
    <Card.Title><strong>Dedicated Customer Support</strong></Card.Title>
    <Card.Text>Our support team is available to assist you and your attendees, ensuring a smooth and satisfying experience for everyone involved.</Card.Text>
    </Card.Body>
    </Card>
    </Col>
    
    <Col md={6}>
    <Card className="h-100 shadow-sm">
    <Card.Body>
    <Card.Title><strong>Post-Event Feedback and Engagement</strong></Card.Title>
    <Card.Text>Engage with attendees post-event to gather feedback, share photos, and build a community around your events, attracting repeat attendees.</Card.Text>
    </Card.Body>
    </Card>
    </Col>
    </Row>
    
    
    <p className="mt-4 text-center">Whether you’re hosting a concert, a corporate conference, or a community gathering, EventTop provides all the tools you need to create, manage, and promote your event effortlessly. Join us and discover how EventTop can elevate your event hosting experience.</p>
                
                <Row className="text-center mb-4">
                  <Col xs={12} sm={6} md={3}>
                    <Image src={eventHostingImage1} alt="Event Creation" fluid className="mb-4" />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Image src={eventHostingImage6} alt="Advanced Ticketing" fluid className="mb-4" />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Image src={eventHostingImage8} alt="Real-Time Analytics" fluid className="mb-4" />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Image src={eventHostingImage3} alt="Marketing Tools" fluid className="mb-4" />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Image src={eventHostingImage4} alt="Payment Options" fluid className="mb-4" />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Image src={eventHostingImage5} alt="Customizable Pages" fluid className="mb-4" />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Image src={eventHostingImage7} alt="Secure Platform" fluid className="mb-4" />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Image src={eventHostingImage2} alt="Customer Support" fluid className="mb-4" />
                  </Col>
                </Row>
                
                




                <div className="mb-4">
                
                  <Card className="mb-3">
                    <Card.Body>
                      <blockquote className="blockquote mb-0">
                        <p>“Organizing both Novruz Gala and Moonlight Serenade with EventTop was seamless. The platform’s support and tools helped make each event a success. I will always come back to EventTop for our future events!”</p>
                        <footer className="blockquote-footer">Nasrin Attari, Event Organizer</footer>
                      </blockquote>
                    </Card.Body>
                  </Card>

                  <Card className="mb-3">
                    <Card.Body>
                      <blockquote className="blockquote mb-0">
                        <p>“Hosting our event on EventTop was a game-changer. The platform is user-friendly, and the support team is incredibly helpful. We managed to reach a broader audience and had a fantastic turnout!”</p>
                        <footer className="blockquote-footer">Leyla Aliyeva, Event Organizer</footer> {/* Azerbaijani name */}
                      </blockquote>
                    </Card.Body>
                  </Card>
                  <Card className="mb-3">
                    <Card.Body>
                      <blockquote className="blockquote mb-0">
                        <p>“EventTop’s tools made it easy to manage ticket sales and track our event’s performance. The analytics provided valuable insights that helped us make informed decisions.”</p>
                        <footer className="blockquote-footer">Murat Yılmaz, Event Organizer</footer> {/* Turkish name */}
                      </blockquote>
                    </Card.Body>
                  </Card>
                  <Card className="mb-3">
                    <Card.Body>
                      <blockquote className="blockquote mb-0">
                        <p>“Thanks to EventTop, our cultural festival was a huge success. The platform’s features made everything so much easier, from ticketing to audience engagement.”</p>
                        <footer className="blockquote-footer">Sara Mirzai, Festival Organizer</footer> {/* Iranian name */}
                      </blockquote>
                    </Card.Body>
                  </Card>
                </div>

                <div className="text-center mb-4">
                  <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center">
                  <Button 
                  href="/registration-form" 
                  className="btn-lg mb-2 mb-sm-0 me-sm-3" 
                  variant="primary" 
                  style={{ width: '320px', boxShadow: '2px 2px 8px rgba(0, 0, 1, 1.9)' }}
                  >
                  Start Hosting Your Event!
                  </Button>
                  <Button 
                  href="/event-organization-instructions" 
                  className="btn-lg" 
                  variant="secondary" 
                  style={{ width: '320px', boxShadow: '2px 2px 8px rgba(0, 0, 1, 1.9)' }}
                  >
                  Event Organization Instructions
                  </Button>
                </div>
                </div>
                
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EventHostingPage;
  