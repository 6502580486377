// CreateEvent.js

import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { FaPlus, FaTrash } from 'react-icons/fa'; // Import FontAwesome icons
import { API_ENDPOINTS } from '../config';
import { FaUpload } from 'react-icons/fa';

const EventForm = ({ editingEvent = null }) => {
    const { id } = useParams(); // Get event ID from the URL
    const navigate = useNavigate(); // For redirecting after successful event creation/edit
    
    const [formData, setFormData] = useState({
        EventName: '',
        EventType: '',
        UserID: '',
        EventDate: new Date(),
        EventCity: '',
        EventCountry: '',
        StartTime: '',
        EndTime: '',
        DoorsOpenTime: '',
        VenueName: '',
        VenueAddress: '',
        VenueDetails: '',
        Theme: '',
        DressCode: '',
        ContactEmail: '',
        ContactPhone: '',
        CovidProtocol: '',
        AccessibilityInfo: '',
        PosterImage: null,
        OrganizerName: '',
        SeatMap: null,
        AboutEvent: '',
    });
    
    const [tickets, setTickets] = useState([{
        TicketType: '',
        Price: '',
        Availability: '',
        PurchaseStartDate: new Date(),
        PurchaseDeadline: new Date(),
        AbsorbServiceFee: false,
        TicketDetails: '',
        Notes: '13% HST will be added at checkout.',
    }]);
    
    const [successMessage, setSuccessMessage] = useState('');
    
    const userId = localStorage.getItem('userId');
    
    // Sanitize a date value to ensure it's valid
    const sanitizeDate = (date) => {
        return date && !isNaN(new Date(date)) ? new Date(date) : null;
    };
    
    // Fetch ticket data for a specific event
    const fetchTicketData = async (eventId) => {
        try {
            const response = await fetch(API_ENDPOINTS.getEventTickets(eventId));
            if (!response.ok) throw new Error('Failed to fetch ticket data');
            const data = await response.json();
            console.log('Ticket data:', data);
            const sanitizedTickets = (data.tickets || []).map(ticket => ({
                ...ticket,
                PurchaseStartDate: sanitizeDate(ticket.PurchaseStartDate),
                PurchaseDeadline: sanitizeDate(ticket.PurchaseDeadline),
            }));
            setTickets(sanitizedTickets);
        } catch (error) {
            console.error('Error fetching ticket data:', error);
            setTickets([]); // Fallback to empty array
        }
    };
    
    // UseEffect to populate form when editingEvent changes

    
    useEffect(() => {
        if (editingEvent) {
            console.log('Editing event detected:', editingEvent);
            setFormData({
                ...formData,
                EventName: editingEvent.EventName || '',
                EventType: editingEvent.EventType || '',
                UserID: editingEvent.UserID || userId || '',
                EventDate: sanitizeDate(editingEvent.EventDate),
                EventCity: editingEvent.EventCity || '',
                EventCountry: editingEvent.EventCountry || '',
                StartTime: editingEvent.StartTime || '',
                EndTime: editingEvent.EndTime || '',
                DoorsOpenTime: editingEvent.DoorsOpenTime || '',
                VenueName: editingEvent.VenueName || '',
                VenueAddress: editingEvent.VenueAddress || '',
                VenueDetails: editingEvent.VenueDetails || '',
                Theme: editingEvent.Theme || '',
                DressCode: editingEvent.DressCode || '',
                ContactEmail: editingEvent.ContactEmail || '',
                ContactPhone: editingEvent.ContactPhone || '',
                CovidProtocol: editingEvent.CovidProtocol || '',
                AccessibilityInfo: editingEvent.AccessibilityInfo || '',
                PosterImage: editingEvent.PosterImage || null,
                OrganizerName: editingEvent.OrganizerName || '',
                SeatMap: editingEvent.SeatMap || null,
                AboutEvent: editingEvent.AboutEvent || '',
            });
            
            fetchTicketData(editingEvent.EventID).catch(error => console.error('Error fetching tickets:', error));
        }
    }, [editingEvent]);
    
    // Handle form changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    
    // Handle date changes
    const handleDateChange = (date) => {
        setFormData((prevData) => ({ ...prevData, EventDate: date }));
    };
    
    // Handle ticket changes
    const handleTicketChange = (index, e) => {
        const { name, value } = e.target;
        const updatedTickets = [...tickets];
        updatedTickets[index][name] = value;
        setTickets(updatedTickets);
    };
    
    const handleServiceFeeChange = (index, e) => {
        const updatedTickets = [...tickets];
        updatedTickets[index].AbsorbServiceFee = e.target.checked;
        setTickets(updatedTickets);
    };
    
    const handlePurchaseStartDateChange = (index, date) => {
        const updatedTickets = [...tickets];
        updatedTickets[index].PurchaseStartDate = sanitizeDate(date);
        setTickets(updatedTickets);
    };
    
    const handleTicketDateChange = (index, date) => {
        const updatedTickets = [...tickets];
        updatedTickets[index].PurchaseDeadline = sanitizeDate(date);
        setTickets(updatedTickets);
    };
    
    // Add or remove tickets
    const addTicket = () => setTickets([...tickets, {
        TicketType: '',
        Price: '',
        Availability: '',
        PurchaseStartDate: new Date(),
        PurchaseDeadline: new Date(),
        AbsorbServiceFee: false,
        TicketDetails: '',
        Notes: '13% HST will be added at checkout.',
    }]);
    
    const removeTicket = (index) => setTickets(tickets.filter((_, i) => i !== index));
    

    


    // Handle file change
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const maxSize = 1 * 1024 * 1024; // 2 MB size limit
        
        if (file) {
            // Check if file exceeds the size limit
            if (file.size > maxSize) {
                alert("File size exceeds the 1 MB limit.");
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    PosterImage: null, // Reset to null if the file is too large
                }));
            } else {
                // Set the file if size is acceptable
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    PosterImage: file,
                }));
            }
        } else {
            // If no file is provided, use the placeholder filename
            setFormData((prevFormData) => ({
                ...prevFormData,
                PosterImage: "placeholder_image.png",
            }));
        }
    };
    
    useEffect(() => {
        console.log('Updated formData', formData.PosterImage);
    }, [formData.PosterImage]);

    useEffect(() => {
        console.log('Editing Event:', editingEvent);
    }, [editingEvent]);

    // Handle form submission
    
    
    
    
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        
        if (!formData.EventName.trim() || !formData.VenueName.trim()) {
            alert('Event Name and Venue Name are required');
            return;
        }
        
        // Ensure `editingEvent` is valid before using it
        const eventId = editingEvent?.EventID;
        const endpoint = eventId ? API_ENDPOINTS.updateEvent(eventId) : API_ENDPOINTS.createEvent;
        const method = eventId ? 'PUT' : 'POST';
        
        const data = new FormData();
        for (const key in formData) {
            data.append(key, formData[key]); // Append all form fields
        }
        data.append('tickets', JSON.stringify(tickets)); // Append tickets as JSON string
        
        try {
            const response = await fetch(endpoint, {
                method: method,
                body: data,
            });
            
            if (!response.ok) {
                const errorMessage = await response.text();
                console.error('Failed to submit event:', errorMessage);
                alert(`Failed to ${eventId ? 'update' : 'create'} event. ${errorMessage}`);
                return;
            }
            
            setSuccessMessage(eventId ? 'Event updated successfully!' : 'Event created successfully!');
            setTimeout(() => navigate('/userpanel'), 2000);
        } catch (error) {
            console.error('Error:', error);
            alert(`An error occurred: ${error.message}`);
        }
    };
    
    
    return (
        <Container className="mt-5">
        <Row className="justify-content-center">
        <Col xs={12} md={12} lg={12}> {/* This ensures the form is full-width on smaller screens */}
        <Card className="shadow-lg p-0">
        <Card.Body>
        <div className="card-header">{id ? 'Edit Event' : 'Create Event'}</div>
        <div className="card-body">
        <form onSubmit={handleSubmit}>
        {/* Event Information */}
        <label class="text-primary fw-bold fs-5">Event Information:</label>
        
        <div className="form-group">
        <label>Event Name:</label>
        <input
        type="text"
        className="form-control"
        name="EventName"
        value={formData.EventName}
        onChange={handleChange}
        />
        </div>
        
        <div className="form-group">
        <label>Event Organizer:</label>
        <input
        type="text"
        className="form-control"
        name="OrganizerName"
        value={formData.OrganizerName}
        onChange={handleChange}
        />
        </div>
        
        <div className="form-group">
        <label>Organizer Contact Email:</label>
        <input
        type="email"
        className="form-control"
        name="ContactEmail"
        value={formData.ContactEmail}
        onChange={handleChange}
        />
        </div>
        
        <div className="form-group">
        <label>Organizer Phone:</label>
        <input
        type="tel"
        className="form-control"
        name="ContactPhone"
        value={formData.ContactPhone}
        onChange={handleChange}
        />
        </div>
        
        <div className="form-group">
        <label>Event Type:</label>
        <div className="dropdown">
        <select className="form-control dropdown-select"
        name="EventType"
        value={formData.EventType}
        onChange={handleChange}
        >
        <option value="">Select Event Type</option>
        <option value="general admission">General Admission</option>
        <option value="banquet hall">Banquet Hall</option>
        <option value="conference">Conference</option>
        <option value="festival">Festival</option>
        <option value="seminar">Seminar</option>
        <option value="workshop">Workshop</option>
        <option value="concert">Concert</option>
        <option value="exhibition">Exhibition</option>
        <option value="trade show">Trade Show</option>
        <option value="networking event">Networking Event</option>
        <option value="charity event">Charity Event</option>
        <option value="product launch">Product Launch</option>
        <option value="award ceremony">Award Ceremony</option>
        <option value="wedding">Wedding</option>
        <option value="birthday party">Birthday Party</option>
        <option value="anniversary celebration">Anniversary Celebration</option>
        <option value="fundraising event">Fundraising Event</option>
        <option value="sports event">Sports Event</option>
        </select>
        <div className="dropdown-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
        <path d="M2.646 5.646a.5.5 0 0 1 .708 0L8 10.293l4.646-4.647a.5.5 0 0 1 .708.708l-5 5a.5.5 0 0 1-.708 0l-5-5a.5.5 0 0 1 0-.708z" />
        </svg>
        </div>
        </div>
        </div>
        
        <div className="form-group">
        <label>Event Date:</label>
        <DatePicker
        selected={formData.EventDate}
        onChange={handleDateChange}
        className="form-control"
        />
        </div>
        
        <div className="form-group">
        <label>Start Time:</label>
        <input
        type="time"
        className="form-control"
        name="StartTime"
        value={formData.StartTime}
        onChange={handleChange}
        />
        </div>
        
        <div className="form-group">
        <label>End Time:</label>
        <input
        type="time"
        className="form-control"
        name="EndTime"
        value={formData.EndTime}
        onChange={handleChange}
        />
        </div>
        
        <div className="form-group">
        <label>Doors Open Time:</label>
        <input
        type="time"
        className="form-control"
        name="DoorsOpenTime"
        value={formData.DoorsOpenTime}
        onChange={handleChange}
        />
        </div>
        
        <div className="form-group">
        <label>Event City:</label>
        <input
        type="text"
        className="form-control"
        name="EventCity"
        value={formData.EventCity}
        onChange={handleChange}
        />
        </div>
        
        <div className="form-group">
        <label>Event Country:</label>
        <input
        type="text"
        className="form-control"
        name="EventCountry"
        value={formData.EventCountry}
        onChange={handleChange}
        />
        </div>
        
        <div className="form-group">
        <label>Theme:</label>
        <input
        type="text"
        className="form-control"
        name="Theme"
        value={formData.Theme}
        onChange={handleChange}
        />
        </div>
        
        <div className="form-group">
        <label>Dress Code:</label>
        <input
        type="text"
        className="form-control"
        name="DressCode"
        value={formData.DressCode}
        onChange={handleChange}
        />
        </div>
        
        <div className="form-group">
        <label>About Event:</label>
            <textarea
            className="form-control"
            name="AboutEvent"
            value={formData.AboutEvent}
            onChange={handleChange}
            style={{ height: '250px' }} // Adjust the height as needed
            ></textarea>
        </div>
        
        <hr></hr>

        <div class="text-primary fw-bold fs-5">Venue Information:</div>
        
        <div className="form-group">
        <label>Venue Name:</label>
        <input
        type="text"
        className="form-control"
        name="VenueName"
        value={formData.VenueName}
        onChange={handleChange}
        />
        </div>
        
        <div className="form-group">
        <label>Venue Address:</label>
        <input
        type="text"
        className="form-control"
        name="VenueAddress"
        value={formData.VenueAddress}
        onChange={handleChange}
        />
        </div>
                
        <div className="form-group">
        <label>Venue Details:</label>
        <textarea
        className="form-control"
        name="VenueDetails"
        value={formData.VenueDetails}
        onChange={handleChange}
        style={{ height: '250px' }} // Adjust the height as needed
        ></textarea>
        </div>

        <div className="form-group">
        <label>Does Event Require a Seat Map?</label>
        <div className="dropdown">
        <select className="form-control dropdown-select"
        name="SeatMapRequired"
        value={formData.SeatMapRequired}
        onChange={handleChange}
        >
        <option value="" className="text-muted">Seat Map Requirement</option>
        <option value="1">Yes</option>
        <option value="0">No</option>
        </select>
        </div>
        </div>
        
        <div className="form-group">
        <label>COVID Protocol:</label>
        <textarea
        className="form-control"
        name="CovidProtocol"
        value={formData.CovidProtocol}
        onChange={handleChange}
        ></textarea>
        </div>
        
        <div className="form-group">
        <label>Accessibility Info:</label>
        <textarea
        className="form-control"
        name="AccessibilityInfo"
        value={formData.AccessibilityInfo}
        onChange={handleChange}
        ></textarea>
        </div>
        
        {/* Event Poster Upload */}
        <div className="form-group mt-4 mb-4 border-top">
        <label className="form-label font-weight-bold text-primary fw-bold fs-5">
        Event Poster:
        </label>
        
        <div
        style={{
            position: 'relative',
            width: '100%',
            height: '300px',
            backgroundImage: `url(${formData.PosterImage || 'path/to/placeholder-image.jpg'})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '10px',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '2px solid #e0e0e0',
        }}
        >
        <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        style={{
            opacity: 0,
            position: 'absolute',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
        }}
        />
        <div
        style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            padding: '20px',
            borderRadius: '10px',
            color: '#007bff',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
        >
        <FaUpload size={30} style={{ marginBottom: '8px' }} />
        <span style={{ fontSize: '1.1rem' }}>Upload photo</span>
        </div>
        </div>
        
        <small className="form-text text-muted">
        <ul style={{ paddingLeft: '1.2em', marginBottom: 0 }}>
        <li>Recommended image aspect ratio: 2/3 or 3/4</li>
        <li>Recommended image size: 1080 x 2160px</li>
        <li>Supported image types: JPG, PNG</li>
        <li>Ensure the file size does not exceed 1 MB</li>
        </ul>
        </small>
        </div>

        
        
        
        
        
        
        
        

                
        <div className="form-group mt-4 mb-4 border-top">        
        <div class="text-primary fw-bold fs-5">Select Your Tickets:</div>
        
        {tickets && tickets.length > 0 ? (
            tickets.map((ticket, index) => (

                <div key={index} className="ticket-info">
                <Row className="align-items-end mb-4">
                {/* Ticket Type */}
                <Col md={3} lg={2} xs={12} className="mb-3">
                <label>Ticket Type: <span style={{ color: 'red' }}>*</span></label>
                <input
                type="text"
                className="form-control"
                name="TicketType"
                value={ticket.TicketType || ''}
                onChange={(e) => handleTicketChange(index, e)}
                required // Makes the field mandatory
                />
                </Col>
                
                {/* Price */}
                <Col md={3} lg={2} xs={6} className="mb-3">
                <label>Price:</label>
                <input
                type="number"
                className="form-control"
                name="Price"
                value={ticket.Price || ''}
                onChange={(e) => handleTicketChange(index, e)}
                required // Makes the field mandatory
                />
                </Col>
                
                {/* Availability */}
                <Col md={3} lg={2} xs={6} className="mb-3">
                <label>Availability: <span style={{ color: 'red' }}>*</span></label>
                <input
                type="number"
                className="form-control"
                name="Availability"
                value={ticket.Availability || ''}
                readOnly // Keep the field read-only
                required // Enforce validation
                />
                </Col>
                
                {/* Purchase Start Date */}
                <Col md={3} lg={2} xs={12} className="mb-3">
                <label>Purchase Start Date:</label>
                <DatePicker
                selected={ticket.PurchaseStartDate || null}
                onChange={(date) => handlePurchaseStartDateChange(index, date)}
                className="form-control"
                />
                </Col>
                
                {/* Purchase Deadline */}
                <Col md={3} lg={2} xs={12} className="mb-3">
                <label>Purchase Deadline:</label>
                <DatePicker
                selected={ticket.PurchaseDeadline || null}
                onChange={(date) => handleTicketDateChange(index, date)}
                className="form-control"
                />
                </Col>
                
                {/* Ticket Details */}
                <Col md={6} lg={4} xs={12} className="mb-3">
                <label>Ticket Details:</label>
                <input
                type="text"
                className="form-control"
                name="TicketDetails"
                value={ticket.TicketDetails || ''}
                onChange={(e) => handleTicketChange(index, e)}
                />
                </Col>
                
                {/* Absorb Service Fee */}
                <Col md={3} lg={2} xs={12} className="text-center mb-3">
                <div className="form-check form-check-inline">
                <label className="form-check-label mr-2">Absorb Service Fee:</label>
                <input
                type="checkbox"
                className="form-check-input"
                checked={!!ticket.AbsorbServiceFee} // Ensure boolean value
                onChange={(e) => handleServiceFeeChange(index, e)}
                />
                </div>
                </Col>
                
                {/* Add/Remove buttons */}
                <Col md={3} xs={12} className="text-center mb-3 mt-3">
                <Button variant="danger" onClick={() => removeTicket(index)} className="mr-2">
                <FaTrash /> {/* Trash icon */}
                </Button>
                <Button variant="primary" onClick={addTicket}>
                <FaPlus /> {/* Plus icon */}
                </Button>
                </Col>
                
                <div>
                {editingEvent && (
                    <small className="form-text text-muted">
                    Note: Ticket capacity cannot be changed during editing. If changes are needed, please{' '}
                    <a href="/contact-us" target="_blank" rel="noopener noreferrer">
                    contact support
                    </a>.
                    </small>
                )}
                </div>
                
                </Row>
                </div>
                
                
                
            ))
        ) : (
            <p>No tickets available. Click the plus button to add one.</p>
        )}
        </div>
        
        <button type="submit" className="btn btn-primary mt-3">
        {editingEvent ? 'Update Event' : 'Create Event'}
        </button>
        
        {successMessage && (
            <div className="alert alert-success mt-3" role="alert">
            {successMessage}
            </div>
        )}
        </form>
        </div>
        </Card.Body>
        </Card>
        </Col>
        </Row>
        
        </Container>
    );
};

export default EventForm;