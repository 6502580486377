import React, { useState } from 'react';
import { Container, Row, Col, Button, Form, Alert, Modal } from 'react-bootstrap';

const ConcertPurchaseSettings = ({ event }) => {
  const [ticketType, setTicketType] = useState('Standard');
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [seatMapVisible, setSeatMapVisible] = useState(false);
  const [seatMap, setSeatMap] = useState(getSeatMap()); // Fetch seat map details
  const [totalPrice, setTotalPrice] = useState(0);
  const [showAlert, setShowAlert] = useState(false);

  const ticketPrices = {
    Standard: 50,
    VIP: 100,
    EarlyBird: 40,
  };

  const handleSeatSelection = (seat) => {
    // Toggle seat selection
    if (selectedSeats.includes(seat)) {
      setSelectedSeats(selectedSeats.filter(s => s !== seat));
    } else {
      setSelectedSeats([...selectedSeats, seat]);
    }
  };

  const handleTicketTypeChange = (e) => {
    const newType = e.target.value;
    setTicketType(newType);
    updateTotalPrice(newType, selectedSeats.length);
  };

  const updateTotalPrice = (type, numberOfSeats) => {
    const pricePerTicket = ticketPrices[type];
    setTotalPrice(pricePerTicket * numberOfSeats);
  };

  const handlePurchase = () => {
    if (selectedSeats.length === 0) {
      setShowAlert(true);
    } else {
      // Proceed to the next step of purchase
      console.log('Proceed to purchase:', { ticketType, selectedSeats, totalPrice });
    }
  };

  const renderSeatMap = () => {
    return seatMap.map((seat) => (
      <Button
        key={seat.id}
        variant={selectedSeats.includes(seat.id) ? 'success' : 'outline-primary'}
        className="seat-button"
        onClick={() => handleSeatSelection(seat.id)}
        disabled={seat.isBooked}
      >
        {seat.label}
      </Button>
    ));
  };

  return (
    <Container className="mt-3">
      <h3 className="text-center">Concert Ticket Purchase</h3>
      <p className="text-center">Purchase settings for Concerts include seat selection and VIP backstage access.</p>

      {showAlert && <Alert variant="danger">Please select at least one seat.</Alert>}

      <Form>
        <Form.Group controlId="ticketType">
          <Form.Label>Select Ticket Type</Form.Label>
          <Form.Control as="select" value={ticketType} onChange={handleTicketTypeChange}>
            <option value="Standard">Standard Admission - $50</option>
            <option value="VIP">VIP Admission - $100</option>
            <option value="EarlyBird">Early Bird - $40 (Limited)</option>
          </Form.Control>
        </Form.Group>

        <div className="mt-3">
          <Button variant="primary" onClick={() => setSeatMapVisible(true)}>
            Select Seats
          </Button>
        </div>

        <Modal show={seatMapVisible} onHide={() => setSeatMapVisible(false)} size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title>Select Your Seats</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="seat-map">{renderSeatMap()}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setSeatMapVisible(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={() => setSeatMapVisible(false)}>
              Confirm Selection
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="mt-3">
          <h5>Total Price: ${totalPrice}</h5>
        </div>

        <div className="text-center mt-4">
          <Button variant="success" onClick={handlePurchase}>
            Proceed to Purchase
          </Button>
        </div>
      </Form>
    </Container>
  );
};

// Dummy seat map generation for demonstration
const getSeatMap = () => {
  return [
    { id: 1, label: 'A1', isBooked: false },
    { id: 2, label: 'A2', isBooked: true },
    { id: 3, label: 'A3', isBooked: false },
    { id: 4, label: 'A4', isBooked: false },
    { id: 5, label: 'B1', isBooked: false },
    { id: 6, label: 'B2', isBooked: true },
    { id: 7, label: 'B3', isBooked: false },
    { id: 8, label: 'B4', isBooked: false },
  ];
};

export default ConcertPurchaseSettings;