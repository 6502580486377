import React from 'react';

const PricingSummary = ({
  totalAmount,
  convenienceFee,
  processingFee,
  salesTax,
  totalWithFees,
}) => {
  return (
  <div className="mt-3" style={{ fontFamily: 'Arial, sans-serif', color: '#333' }}>
    <h5 style={{ borderBottom: '2px solid #007bff', paddingBottom: '5px', color: '#007bff' }}>Pricing Summary</h5>
    {totalAmount > 0 ? (
    <div style={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
      <p className="mb-1" style={{ fontSize: '16px' }}>
        <strong>Subtotal:</strong> ${totalAmount.toFixed(2)}
      </p>
      <p className="mb-1" style={{ fontSize: '14px', color: '#555' }}>
        <strong>Convenience Fee:</strong> ${convenienceFee.toFixed(2)}
      </p>
      <p className="mb-1" style={{ fontSize: '14px', color: '#555' }}>
        <strong>Processing Fee:</strong> ${processingFee.toFixed(2)}
      </p>
      <p className="mb-1" style={{ fontSize: '14px' }}>
        <strong>Sales Tax (13% HST):</strong> ${salesTax.toFixed(2)}
      </p>
      <p className="mb-1" style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
        <strong>Total (Tax & Fees included):</strong> ${totalWithFees.toFixed(2)}
      </p>
    </div>
    ) : (
    <p style={{ fontStyle: 'italic', color: '#999' }}>No tables selected yet.</p>
    )}
  </div>
  );
};

export default PricingSummary;