import React from 'react';
import { Table } from 'react-bootstrap';

const SelectedTables = ({ selectedSeats, tableOptions }) => {
  if (!selectedSeats || Object.keys(selectedSeats).length === 0) {
    return <p style={{ fontStyle: 'italic', color: '#999' }}>No tables selected yet.</p>;
  }

  return (
    <div className="mt-3" style={{ fontFamily: 'Arial, sans-serif', color: '#333' }}>
      <h5 style={{ borderBottom: '2px solid #007bff', paddingBottom: '5px', color: '#007bff' }}>Selected Tables</h5>
      <Table
        striped
        bordered
        hover
        size="sm"
        style={{
          borderRadius: '8px',
          overflow: 'hidden',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#fff',
        }}
      >
        <thead style={{ backgroundColor: '#007bff', color: '#fff' }}>
          <tr>
            <th>Table Number</th>
            <th>Price Per Seat</th>
            <th>Total Seats</th>
            {Object.values(selectedSeats).some(({ kidSeats }) => kidSeats > 0) && (
              <th>Kid Seats</th>
            )}
            <th>Total Price</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(selectedSeats).map(([tableId, { price, quantity, kidSeats, totalPrice }]) => {
            // Find table details from tableOptions
            const table = tableOptions.find((t) => t.TableID === parseInt(tableId, 10));
            
            // Ensure valid data is displayed
            const tableNumber = table?.TableNumber || `Table ${tableId}`;
            const pricePerSeat = price ? `$${price.toFixed(2)}` : 'N/A';
            const totalPriceDisplay = totalPrice ? `$${totalPrice.toFixed(2)}` : 'N/A';

            return (
              <tr key={tableId}>
                <td>{tableNumber}</td>
                <td>{pricePerSeat}</td>
                <td>{quantity || 0}</td>
                {kidSeats > 0 && <td>{kidSeats}</td>}
                <td style={{ fontWeight: 'bold', color: '#007bff' }}>{totalPriceDisplay}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default SelectedTables;