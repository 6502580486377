// saveGeneralAdmissionData.js
import { API_ENDPOINTS } from '../../config';

// Function to save general admission data
const saveGeneralAdmissionData = async ({ event, buyerInfo, selectedTickets, totalWithFees, paymentReference }) => {
  try {
    // Construct the payload to save the customer info and ticket data
    const customerData = {
      eventID: event.EventID,
      eventName: event.EventName,
      buyer: {
        firstName: buyerInfo.firstName,
        lastName: buyerInfo.lastName,
        email: buyerInfo.email,
        phone: buyerInfo.phone,
        address: buyerInfo.address,
      },
      tickets: Object.keys(selectedTickets).map((ticketId) => {
        const ticketDetails = selectedTickets[ticketId];
        return {
          ticketID: ticketId,
          type: ticketDetails.ticketType || ticketDetails.tableType || ticketDetails.tableNumber || 'Unknown',
          quantity: ticketDetails.quantity,
          price: ticketDetails.price,
        };
      }),
      totalAmount: totalWithFees,
      paymentReference: paymentReference || 'N/A',
    };

    // Send the data to the backend
    const response = await fetch(`${API_ENDPOINTS.saveCustomerInfo}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(customerData),
    });

    if (!response.ok) {
      if (response.status === 409) {
        console.log('Customer information already exists.');
      } else {
        throw new Error('Failed to save customer data.');
      }
    } else {
      console.log('Customer information and tickets saved successfully.');
    }
  } catch (error) {
    console.error('Error saving general admission data:', error);
  }
};

export default saveGeneralAdmissionData;