import React, { useState } from "react";
import { Form, Button, Container, Alert, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { API_ENDPOINTS } from "../config"; // Update with your API endpoints if necessary

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      setLoading(true);

      // Make the API call
      const response = await fetch(API_ENDPOINTS.forgotPassword, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      setLoading(false);

      if (response.ok) {
        const data = await response.json();
        setSuccess(data.message || "Password reset link has been sent to your email.");
      } else {
        const errorData = await response.json();
        setError(errorData.error || "Failed to process your request. Please try again.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error submitting forgot password request:", error);
      setError("An unexpected error occurred. Please try again later.");
    }
  };

  return (
    <Container className="d-flex flex-column justify-content-center align-items-center vh-100">
      <div className="w-100" style={{ maxWidth: "500px" }}>
        <h1 className="text-center mb-4">Forgot Password</h1>

        {/* Success Alert */}
        {success && <Alert variant="success">{success}</Alert>}

        {/* Error Alert */}
        {error && <Alert variant="danger">{error}</Alert>}

        {/* Forgot Password Form */}
        <Form onSubmit={handleSubmit} className="shadow-lg p-4 rounded border">
          <Form.Group className="mb-3">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>
          <div className="d-grid">
            <Button type="submit" variant="primary" disabled={loading}>
              {loading ? (
                <>
                  <Spinner animation="border" size="sm" className="me-2" /> Submitting...
                </>
              ) : (
                "Reset Password"
              )}
            </Button>
          </div>
        </Form>

        <div className="text-center mt-3">
          <Button
            variant="link"
            onClick={() => navigate("/sign-in")}
            className="text-decoration-none"
          >
            Back to Login
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default ForgotPassword;