import React from 'react';

const OnlinePaymentGuide = () => {
  return (
    <div className="container my-5">
      <h1 className="text-center mb-4">Online Payment Guide</h1>

      {/* Intro Section */}
      <div className="text-center mb-4">
        <p className="lead">
          Our secure payment system is powered by <strong>Stripe</strong>, a global leader in online payments.
          Follow this guide to complete your transaction quickly and safely.
        </p>
      </div>

      {/* Steps Section */}
      <div className="card mb-4">
        <div className="card-body">
          <h2 className="card-title">Step-by-Step Payment Instructions</h2>
          <ol className="list-group list-group-numbered">
            <li className="list-group-item">
              <strong>Choose Your Product or Service:</strong> Navigate to the item you wish to purchase and click "Buy Now" or "Proceed to Checkout."
            </li>
            <li className="list-group-item">
              <strong>Review Your Order:</strong> Ensure your order details are correct, including the total amount.
            </li>
            <li className="list-group-item">
              <strong>Enter Your Payment Details:</strong> Fill in the secure payment form provided by Stripe:
              <ul className="mt-2">
                <li>Card Number: Your 16-digit number.</li>
                <li>Expiry Date: The month and year printed on your card.</li>
                <li>CVV: The 3 or 4-digit security code.</li>
                <li>Billing Information: Ensure it matches your card statement.</li>
              </ul>
            </li>
            <li className="list-group-item">
              <strong>Verify and Complete:</strong> Click "Pay" to process your payment securely.
              A confirmation screen will appear once your payment is successful.
            </li>
          </ol>
        </div>
      </div>

      {/* Security & Privacy Section */}
      <div className="card mb-4">
        <div className="card-body">
          <h2 className="card-title">Security & Privacy</h2>
          <p>
            We prioritize your security. Payments are processed via Stripe, which is PCI DSS compliant, meaning your information is encrypted and never stored on our servers.
          </p>
          <p>
            <strong>Why Stripe?</strong> Stripe uses advanced fraud detection and security measures and is trusted by millions of companies worldwide.
          </p>
        </div>
      </div>

      {/* Troubleshooting Section */}
      <div className="card mb-4">
        <div className="card-body">
          <h2 className="card-title">Troubleshooting Payment Issues</h2>
          <p>Here are common solutions if you face issues:</p>
          <ul className="list-group">
            <li className="list-group-item">
              <strong>Card Declined:</strong> Check if the card details are correct and that your card is active and not over its limit.
            </li>
            <li className="list-group-item">
              <strong>Payment Failed:</strong> Contact your bank to ensure there are no restrictions on your card.
            </li>
            <li className="list-group-item">
              <strong>Double Check:</strong> Ensure your internet connection is stable and refresh the page if needed.
            </li>
          </ul>
        </div>
      </div>

      {/* Contact Support Section */}
      <div className="text-center mt-4">
        <p>
          Need help? Email our support team at <a href="mailto:info@eventtop.ca" className="text-decoration-none">info@eventtop.ca</a> for assistance.
        </p>
      </div>
    </div>
  );
};

export default OnlinePaymentGuide;