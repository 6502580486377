// UserPanel.js
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchWithAuth } from '../react-auth-app/auth';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import CreateEvent from '../create-event/CreateEvent';
import UserInfo from '../react-auth-app/userInfo';
import TicketsTable from '../react-auth-app/TicketsTable';
import OrganizedEventsTable from '../react-auth-app/OrganizedEventsTable';
import { API_ENDPOINTS } from '../config';
import './UserPanel.css';
import { FaInfoCircle, FaCalendarPlus, FaTicketAlt, FaTasks, FaSignOutAlt } from 'react-icons/fa';

const UserPanel = () => {
  const navigate = useNavigate();
  const [tickets, setTickets] = useState([]);
  const [organizedEvents, setOrganizedEvents] = useState([]);
  const [userData, setUserData] = useState(null);
  const [editingEvent, setEditingEvent] = useState(null);
  const [showCreateEventForm, setShowCreateEventForm] = useState(false);
  const [showOrganizedEvents, setShowOrganizedEvents] = useState(false);

  const fetchUserData = useCallback(async (userId) => {
    try {
      const response = await fetchWithAuth(API_ENDPOINTS.getUser(userId));
      const data = await response.json();
      setUserData(data);
    } catch (error) {
      console.error('Error fetching user data:', error);
      navigate('/sign-in');
    }
  }, [navigate]);

  const fetchTickets = useCallback(async (userId) => {
    try {
      const response = await fetchWithAuth(API_ENDPOINTS.getTickets(userId));
      if (!response.ok) {
        if (response.status === 404) {
          setTickets([]); // Set an empty array to clear previous tickets
          console.warn('No tickets found for this user');
          return; // Gracefully handle the case where no tickets are found
        }
        throw new Error('Failed to fetch tickets');
      }
      
      const data = await response.json();
      setTickets(data);
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  }, []);

  const fetchOrganizedEvents = useCallback(async (userId) => {
    try {
      const response = await fetchWithAuth(API_ENDPOINTS.organizedEvents(userId));
      const data = await response.json();
      setOrganizedEvents(data);
    } catch (error) {
      console.error('Error fetching organized events:', error);
    }
  }, []);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const accessToken = localStorage.getItem('accessToken');
    
    if (userId && accessToken) {
      fetchUserData(userId); // Fetch user data only once
    } else {
      navigate('/sign-in'); // Redirect to sign-in if not authenticated
    }
  }, [fetchUserData, navigate]);
  
  // Separate useEffect for tickets and organized events
  useEffect(() => {
    if (userData) {
      // Only fetch tickets and events once userData is loaded
      fetchTickets(userData.id);
      fetchOrganizedEvents(userData.id);
    }
  }, [userData, fetchTickets, fetchOrganizedEvents]);
  
  const handleCreateEvent = () => {
    setShowCreateEventForm(true);
    setShowOrganizedEvents(false);
    setEditingEvent(null);
  };

  const handleShowPurchases = () => {
    setShowCreateEventForm(false);
    setShowOrganizedEvents(false);
  };

  const handleShowOrganizedEvents = () => {
    setShowCreateEventForm(false);
    setShowOrganizedEvents(true);
  };

  const handleEditEvent = (event) => {
    setEditingEvent(event); // Set the selected event as the event to edit
    setShowCreateEventForm(true); // Show the form for editing
  };

  const handleDeleteEvent = async (eventId) => {
    //console.log('eventID is:',event.EventID)
    //console.log('API_ENDPOINTS.deleteEvent(eventId) is:',API_ENDPOINTS.deleteEvent(eventId))
    if (window.confirm('Are you sure you want to delete this event?')) {
      try {
        const response = await fetch(API_ENDPOINTS.deleteEvent(eventId), {
          method: 'DELETE',
        });
        if (response.ok) {
          alert('Event deleted successfully!');
          if (userData) fetchOrganizedEvents(userData.id);
        } else {
          console.error('Failed to delete event.');
        }
      } catch (error) {
        console.error('Error deleting event:', error);
      }
    }
  };

  const handleSignOut = () => {
    setUserData(null);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userId');
    document.cookie = 'refreshToken=; Max-Age=0; path=/; domain=' + window.location.hostname;
    navigate('/sign-in');
    window.location.reload(); // Force a page reload to clear any lingering state
  };

  const handleEventCreated = () => {
    if (userData) fetchOrganizedEvents(userData.id);
    setShowCreateEventForm(false);
  };
  
  const handleSendComplimentaryTicket = async (eventId) => {
    try {
      const response = await fetch(API_ENDPOINTS.sendComplimentaryTicket(eventId), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ /* Ticket details here */ }),
      });
      if (response.ok) {
        alert('Complimentary ticket sent successfully!');
      } else {
        console.error('Failed to send complimentary ticket.');
      }
    } catch (error) {
      console.error('Error sending complimentary ticket:', error);
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card className="shadow-lg p-2">
            <Card.Body>
              <h2 className="card-title text-center">User Panel</h2>
              {userData && <UserInfo userData={userData} />}
              
    {/* Add enhanced instructions here */}
    <div className="alert alert-info text-center mb-4 shadow-sm border border-primary rounded">
    <h5 className="mb-4">
    <FaInfoCircle className="me-2 text-primary" size={24} /> <strong>Control Panel Instructions</strong>
    </h5>
    <div className="row g-3">
    <div className="col-md-3 col-sm-6 text-center">
    <FaCalendarPlus className="text-primary mb-2" size={40} />
    <p>
    <strong>New Event:</strong> <br />
    Create and publish a new event for your audience.
    </p>
    </div>
    <div className="col-md-3 col-sm-6 text-center">
    <FaTicketAlt className="text-info mb-2" size={40} />
    <p>
    <strong>Past Tickets:</strong> <br />
    View all the tickets you have purchased for past events.
    </p>
    </div>
    <div className="col-md-3 col-sm-6 text-center">
    <FaTasks className="text-dark mb-2" size={40} />
    <p>
    <strong>Event Dashboard:</strong> <br />
    Manage and review events you have organized.
    </p>
    </div>
    <div className="col-md-3 col-sm-6 text-center">
    <FaSignOutAlt className="text-warning mb-2" size={40} />
    <p>
    <strong>Sign Out:</strong> <br />
    Safely log out from your account.
    </p>
    </div>
    </div>
    </div>
              
    <div className="row g-3 text-center mb-3">
    <div className="col-12 col-sm-6 col-md-3">
    <Button
    variant="outline-primary"
    className="w-100 d-flex align-items-center justify-content-center"
    onClick={handleCreateEvent}
    >
    <FaCalendarPlus className="me-2" size={18} />
    <span>New Event</span>
    </Button>
    </div>
    <div className="col-12 col-sm-6 col-md-3">
    <Button
    variant="outline-info"
    className="w-100 d-flex align-items-center justify-content-center"
    onClick={handleShowPurchases}
    >
    <FaTicketAlt className="me-2" size={18} />
    <span>Past Tickets</span>
    </Button>
    </div>
    <div className="col-12 col-sm-6 col-md-3">
    <Button
    variant="outline-dark"
    className="w-100 d-flex align-items-center justify-content-center"
    onClick={handleShowOrganizedEvents}
    >
    <FaTasks className="me-2" size={18} />
    <span>Event Dashboard</span>
    </Button>
    </div>
    <div className="col-12 col-sm-6 col-md-3">
    <Button
    variant="outline-warning"
    className="w-100 d-flex align-items-center justify-content-center"
    onClick={handleSignOut}
    >
    <FaSignOutAlt className="me-2" size={18} />
    <span>Sign Out</span>
    </Button>
    </div>
    </div>    

              {showCreateEventForm ? (
                <CreateEvent userData={userData} onEventCreated={handleEventCreated} editingEvent={editingEvent} />
              ) : showOrganizedEvents ? (
                <OrganizedEventsTable organizedEvents={organizedEvents} handleEditEvent={handleEditEvent} handleDeleteEvent={handleDeleteEvent} />
              ) : (
                tickets.length > 0 ? (
                  <TicketsTable tickets={tickets} />
                ) : (
                  <div className="text-center text-muted mt-3">
                  <h5>No tickets have been purchased yet.</h5>
                  </div>
                )
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UserPanel;