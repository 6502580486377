import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const BanquetHallLayout = ({ tableOptions, handleSeatSelection }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentTable, setCurrentTable] = useState(null);
  const [totalSeats, setTotalSeats] = useState('');
  const [kidSeats, setKidSeats] = useState('');

  if (!tableOptions || tableOptions.length === 0) {
    return <p>No tables available.</p>;
  }

  // Function to determine styles dynamically
  const determineStyles = (element) => {
    const isTable = element.ElementType === 'Table';
    const isDanceFloor = element.ElementType === 'Dance Floor';
    const isStage = element.ElementType === 'Stage';
    
    const baseColor = element.AvailableSeats === 0
    ? '#ccc'
    : element.ElementColor ||
    (isTable
      ? element.TableType === 'VIP'
      ? '#ffd700' // Gold for VIP tables
      : '#007bff' // Blue for standard tables
      : isDanceFloor
      ? '#fffaad' // Light yellow for dance floor
      : isStage
      ? '#6c757d' // Grey for stage
      : '#ccc'); // Default grey for unknown
    
    const containerStyles = {
      position: 'absolute',
      top: `${element.LocationY || 0}%`,
      left: `${element.LocationX || 0}%`,
      width: isTable ? 'clamp(15px, 5vw, 30px)' : `${element.Width || 10}%`,
      height: isTable ? 'clamp(15px, 5vw, 30px)' : `${element.Height || 10}%`,
      borderRadius: isTable ? '50%' : '0%',
      backgroundColor: baseColor,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: isTable && element.AvailableSeats > 0 ? 'pointer' : 'not-allowed',
      color: isTable ? '#000' : '#004085',
      textAlign: 'center',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      fontSize: isTable ? 'clamp(0.6rem, 1.5vw, 0.8rem)' : '1.2rem',
    };
    
    const textStyles = isStage && element.Width < element.Height
    ? { transform: 'rotate(270deg)', whiteSpace: 'nowrap' } // Rotate text and prevent wrapping
    : { transform: 'none' }; // No rotation for other elements
    
    return { containerStyles, textStyles };
  };  
  

  const handleModalSubmit = () => {
    if (!currentTable) return;
    
    const { TableID, TableNumber, TableType, PricePerSeat, AvailableSeats, KidsDiscount, DiscountAmount } = currentTable;
    
    const totalSeatsNum = parseInt(totalSeats, 10);
    const kidSeatsNum = parseInt(kidSeats, 10) || 0; // Default kidSeats to 0 if not provided
    
    // Validation
    if (
      isNaN(totalSeatsNum) ||
      totalSeatsNum <= 0 ||
      totalSeatsNum > AvailableSeats ||
      (KidsDiscount && DiscountAmount > 0 && (
        isNaN(kidSeatsNum) || 
        kidSeatsNum < 0 || 
        kidSeatsNum > totalSeatsNum
      ))
    ) {
      alert('Invalid input. Please ensure your inputs are correct.');
      return;
    }
    
    // Calculate total price
    const adultSeats = totalSeatsNum - kidSeatsNum;
    const totalPrice = KidsDiscount && DiscountAmount > 0
    ? adultSeats * PricePerSeat + kidSeatsNum * (PricePerSeat - DiscountAmount)
    : totalSeatsNum * PricePerSeat; // No discount if KidsDiscount is false or DiscountAmount is 0
    
    // Call the parent selection handler
    handleSeatSelection({
      tableId: TableID,
      tableNumber: TableNumber,
      tableType: TableType,
      price: PricePerSeat,
      totalSeats: totalSeatsNum,
      kidSeats: KidsDiscount && DiscountAmount > 0 ? kidSeatsNum : 0, // Include kidSeats only if discount is enabled
      totalPrice,
    });
    
    // Reset modal
    setShowModal(false);
    setTotalSeats('');
    setKidSeats('');
  };

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '60vh',
        maxWidth: '100vw',
        overflow: 'hidden',
        backgroundColor: '#f8f9fa', // Light background color
        border: '2px solid #ccc', // Explicit border definition
        borderRadius: '10px', // Optional for rounded corners
      }}
    >
      {tableOptions.map((element) => {
        const styles = determineStyles(element);

        return (
          <div
          key={element.TableID || element.ElementID}
          style={determineStyles(element).containerStyles}
          onClick={() => {
            if (element.ElementType === 'Table') {
              if (element.AvailableSeats === 0) {
                alert(`Table ${element.TableNumber} is not available for sale.`);
              } else {
                setCurrentTable(element);
                setShowModal(true);
              }
            }
          }}
          >
          <span style={determineStyles(element).textStyles}>
          {element.TableNumber || element.ElementType}
          </span>
          </div>
        );
      })}

      {/* Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Seat Selection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
    {currentTable && (
      <>
      <p style={{ margin: 0 }}>
      <strong>Table:</strong> {currentTable.TableNumber}
      </p>
      <p style={{ margin: 0 }}>
      <strong>Price per seat:</strong> ${currentTable.PricePerSeat.toFixed(2)}
      </p>
      <p style={{ margin: 0 }}>
      <strong>Available seats:</strong> {currentTable.AvailableSeats}
      </p>
      {currentTable.DiscountAmount > 0 && (
        <div
        style={{
          margin: '10px 0',
          padding: '10px',
          border: '1px solid #007bff',
          backgroundColor: '#e7f3ff',
          color: '#004085',
          borderRadius: '4px',
        }}
        >
        <strong>Note:</strong> A discount of ${currentTable.DiscountAmount.toFixed(2)} will be applied to kid selections.
        </div>
      )}
      </>
    )}

    <Form>
    <Form.Group controlId="totalSeats" className="mb-3 mt-4">
    <Form.Label>Total Seats</Form.Label>
    <Form.Control
    type="number"
    inputMode="numeric" // Ensures numeric keyboard is shown
    value={totalSeats}
    onChange={(e) => setTotalSeats(e.target.value)}
    placeholder="Enter total seats"
    />
    </Form.Group>
    
    {/* Conditionally render Kid Tickets field */}
    {currentTable && (currentTable.KidsDiscount || currentTable.DiscountAmount > 0) && (
      <Form.Group controlId="kidSeats" className="mb-3">
      <Form.Label>Kid Tickets</Form.Label>
      <Form.Control
      type="number"
      inputMode="numeric" // Ensures numeric keyboard is shown
      value={kidSeats}
      onChange={(e) => setKidSeats(e.target.value)}
      placeholder="Enter kid tickets"
      />
      </Form.Group>
    )}
    </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleModalSubmit}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BanquetHallLayout;