import React from 'react';
import { Row } from 'react-bootstrap';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';

const SocialShare = ({ event }) => (
    <Row className="mt-4">
        <h4 className="text-center">Share Your Experience</h4>
        <div className="d-flex justify-content-center flex-wrap">
            <a
                href={`https://twitter.com/intent/tweet?url=${encodeURIComponent('https://eventtop.ca')}&text=${encodeURIComponent(`🎉 Just had an amazing experience booking tickets for ${event.EventName} at EventTop! 🌟 Get yours now at EventTop.ca 🎟️ #EventTop #BestExperience #TicketBooking`)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary m-1"
            >
                <FaTwitter className="me-2" />
                Share on Twitter
            </a>
            <a
                href="https://www.instagram.com/eventtop.ca"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-danger m-1"
            >
                <FaInstagram className="me-2" />
                Share on Instagram
            </a>
            <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent('https://eventtop.ca')}`}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary m-1"
            >
                <FaFacebook className="me-2" />
                Share on Facebook
            </a>
        </div>
    </Row>
);

export default SocialShare;