// SignInForm.js

import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { API_ENDPOINTS } from '../config'; // Adjust the path as necessary
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import icons for show/hide functionality

const SignInForm = ({ setUserData }) => {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const navigate = useNavigate();
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    
    try {
      const response = await fetch(API_ENDPOINTS.login, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        credentials: 'include', // Include credentials in the request
      });
      
      if (!response.ok) {
        throw new Error('Sign-in failed');
      }
      
      const data = await response.json();
      
      const { accessToken, userId } = data;
      if (!accessToken) {
        throw new Error('Invalid token received');
      }
      
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('userId', userId); // Save only the user ID to local storage
      setUserData(data); // Update user data in the parent component
      navigate('/userpanel'); // Navigate to UserPanel
    } catch (error) {
      console.error('Error during sign-in:', error);
      setError('Invalid username or password');
    }
  };
  
  return (
    <div className="container-fluid mt-5">
    <div className="row justify-content-center p-3">
    <div className="col-12 col-md-8 col-lg-6 border rounded border-gray p-3 d-flex flex-column flex-md-row align-items-center">
    {/* Image for larger screens */}
    <div
    className="me-3 d-none d-md-block flex-shrink-0"
    style={{ width: '45%', height: '100%' }}
    >
    <img
    src="images/photo-signin.jpeg"
    alt="signin"
    className="img-fluid w-100 h-100"
    />
    </div>
    
    {/* Form content */}
    <div className="w-100">
    <h2>Login</h2>
    <h6 className="mb-4">
    New to EventTop? <Link to="/registration-form">Sign Up</Link>
    </h6>
    {error && <div className="alert alert-danger">{error}</div>}
    <form onSubmit={handleSubmit}>
    <div className="mb-3">
    <label htmlFor="username" className="form-label">
    Username
    </label>
    <input
    type="text"
    className="form-control"
    id="username"
    name="username"
    value={formData.username}
    onChange={handleInputChange}
    style={{ width: '100%' }} // Ensure the width is consistent
    />
    </div>
    <div className="mb-3">
    <label htmlFor="password" className="form-label">
    Password
    </label>
    <div className="input-group" style={{ width: '100%' }}>
    <input
    type={showPassword ? 'text' : 'password'}
    className="form-control"
    id="password"
    name="password"
    value={formData.password}
    onChange={handleInputChange}
    />
    <span
    className="input-group-text"
    onClick={() => setShowPassword(!showPassword)}
    style={{ cursor: 'pointer' }}
    >
    {showPassword ? <FaEyeSlash /> : <FaEye />}
    </span>
    </div>
    </div>
    <div className="d-flex justify-content-between w-100 mb-2">
    <button type="submit" className="btn btn-primary">
    Sign In
    </button>
    <Link
    to="/forgot-password"
    className="text-decoration-none"
    >
    Forgot password?
    </Link>
    </div>
    <div className="mt-4 small text-muted">
    By continuing past this page, you agree to the Terms of Use
    and understand that information will be used as described in
    our Privacy Policy.
    </div>
    </form>
    </div>
    </div>
    </div>
    </div>
  );
};

export default SignInForm;