import React from 'react';

const UserInfo = ({ userData }) => {
  return (
    <>
      <p className="mb-0">Welcome <strong>{userData.firstname} {userData.lastname}</strong>!</p>
      <p className="mb-3">Email: {userData.email}</p>
    </>
  );
};

export default UserInfo;