import React from 'react';
import { Container, Row, Col, Card, Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import placeholderImage0 from '../assets/OrganizerCreatEvent.png'; // Use your actual path to the image
import placeholderImage1 from '../assets/OrganizerPlanView.png'; // Use your actual path to the image
import placeholderImage2 from '../assets/EventPage.png'; // Use your actual path to the image
import placeholderImage3 from '../assets/OrganizerTicketAnalytics.png'; // Use your actual path to the image
import placeholderImage4 from '../assets/FlexiblePaymentOptions.png'; // Use your actual path to the image
import placeholderImage5 from '../assets/FlexiblePaymentOptions2.png'; // Use your actual path to the image

const EventOrganizationInstructions = () => {
  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={12}>
          <Card className="shadow-lg mb-4">
            <Card.Body>
              <h1 className="text-center">Event Organization Instructions</h1>
              <p className="lead text-center">
                Discover the powerful tools and features EventTop offers to help you organize and manage successful events.
              </p>

              <h3>1. Seamless Event Creation</h3>
              <p>
                EventTop’s user-friendly interface lets you create and customize your event page with minimal effort. Simply enter your event’s details—venue information, date, ticket prices, and more. 
                With intuitive forms and clear prompts, you can complete setup in just a few clicks, making it easier than ever to bring your event vision to life.
              </p>
              {/* Image with frame and shadow */}
              <div className="d-flex justify-content-center my-4">
              <Image 
              src={placeholderImage0} 
              alt="Example Organizer Create Event Form" 
              fluid 
              className="border rounded shadow-lg p-2" // Bootstrap classes for border, rounded corners, shadow, and padding
              style={{ maxWidth: '80%', borderColor: '#ddd', borderWidth: '10px' }}
              />
              </div>
              {/* Image with frame and shadow */}
              <div className="d-flex justify-content-center my-4">
              <Image 
              src={placeholderImage1} 
              alt="Example Organizer Control Panel" 
              fluid 
              className="border rounded shadow-lg p-2" // Bootstrap classes for border, rounded corners, shadow, and padding
              style={{ maxWidth: '80%', borderColor: '#ddd', borderWidth: '10px' }}
              />
              </div>

              <h3>2. Advanced Ticketing System</h3>
              <p>
                Our platform supports a variety of ticketing options, from general admission to VIP access. You can set up different ticket types, allocate discounts, and specify quantity limits to 
                cater to different audience segments. Ticket distribution is automated, allowing attendees to receive their tickets via email instantly upon purchase. Plus, with ticket management features, 
                you can monitor ticket sales in real-time and make adjustments as needed.
              </p>
              {/* Image with frame and shadow */}
              <div className="d-flex justify-content-center my-4">
              <Image 
              src={placeholderImage2} 
              alt="Example Event Page" 
              fluid 
              className="border rounded shadow-lg p-2" // Bootstrap classes for border, rounded corners, shadow, and padding
              style={{ maxWidth: '80%', borderColor: '#ddd', borderWidth: '10px' }}
              />
              </div>

              <h3>3. Real-Time Analytics</h3>
              <p>
                With EventTop’s analytics dashboard, you gain access to essential data about your event’s performance. Track ticket sales, see peak purchasing times, and understand where your audience 
                is coming from. Real-time analytics enable you to make informed decisions, such as adjusting marketing efforts or ticket pricing, maximizing your event’s success.
              </p>              
              {/* Image with frame and shadow */}
              <div className="d-flex justify-content-center my-4">
              <Image 
              src={placeholderImage3} 
              alt="Example Organizer Control Panel" 
              fluid 
              className="border rounded shadow-lg p-2" // Bootstrap classes for border, rounded corners, shadow, and padding
              style={{ maxWidth: '80%', borderColor: '#ddd', borderWidth: '10px' }}
              />
              </div>

              <h3>4. Comprehensive Marketing Tools</h3>
              <p>
                Promote your event through EventTop’s built-in marketing tools. Use email campaigns to reach your mailing list, integrate with social media platforms to expand your reach, 
                and leverage SEO optimization to ensure your event page ranks well in search results. With targeted marketing options, you can focus your efforts on reaching the right audience.
              </p>

              <h3>5. Flexible Payment Options</h3>
              <p>
                We understand that every attendee has different payment preferences. EventTop supports multiple payment methods, including credit and debit cards, online banking, and mobile wallets. 
                Our secure payment gateway ensures a smooth transaction experience, providing peace of mind for both you and your attendees. If you choose, you can even absorb ticket fees, 
                offering attendees a seamless experience without additional charges.
              </p>
              
    {/* Responsive Image Display */}
    <div className="d-flex flex-wrap justify-content-center align-items-center my-4">
    <div className="col-12 col-md-6 d-flex justify-content-center my-2">
    <Image 
    src={placeholderImage4} 
    alt="Flexible payment Options" 
    fluid 
    className="border rounded shadow-lg p-2" 
    style={{ borderColor: '#ddd', borderWidth: '10px' }}
    />
    </div>
    <div className="col-12 col-md-6 d-flex justify-content-center my-2">
    <Image 
    src={placeholderImage5} 
    alt="Flexible payment Options" 
    fluid 
    className="border rounded shadow-lg p-2" 
    style={{ borderColor: '#ddd', borderWidth: '10px' }}
    />
    </div>
    </div>                 
              



              <h3>6. Customizable Event Pages</h3>
              <p>
                Make your event page uniquely yours by customizing it to reflect your brand and theme. Add images, videos, banners, and personalized text to engage visitors. 
                EventTop allows you to showcase key details prominently, including event schedules, guest speakers, sponsors, and more. Each event page is designed to capture 
                the essence of your event, making it appealing and memorable to potential attendees.
              </p>

              <h3>7. Exceptional Customer Support</h3>
              <p>
                Our dedicated support team is available to assist you with any questions or issues you may encounter. From setup to post-event feedback, we’re here to provide 
                expert guidance every step of the way. Whether you need help with ticketing, customization, or troubleshooting, EventTop support is just a call or message away, 
                ensuring a smooth and stress-free experience.
              </p>

              <h3>8. Secure and Reliable Platform</h3>
              <p>
                Security is our top priority. EventTop uses advanced encryption and data protection methods to safeguard your event and attendee information. Our platform is 
                continuously monitored to prevent disruptions, allowing your event to run smoothly. With EventTop, you can trust that your data is secure, and your event is backed 
                by a reliable infrastructure designed for high performance.
              </p>



              <div className="text-center mt-4">
                <Link to="/registration-form">
                  <Button variant="primary" size="lg">Get Started with EventTop</Button>
                </Link>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EventOrganizationInstructions;