import React from 'react';
import { Container, Row, Col, Button, Table } from 'react-bootstrap';

const PromotionalServices = () => {
  return (
    <div>
      {/* Banner Section */}
      <div
        style={{
          background: 'linear-gradient(135deg, #3E8EDE, #B2DFFC)',
          padding: '40px 0',
          color: '#fff',
        }}
      >
        <Container className="text-center">
        <Row className="justify-content-center">
        <Col lg={8}>
        <h1 className="fw-bold display-5">
        Amplify Your Event's Reach with Our Services
              </h1>
              <p className="fs-5 mt-3">
                Choose from a variety of tailored promotional services to ensure
                your event gets the attention it deserves. Let us help you maximize
                your impact!
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Table Section */}
    <Container className="my-5">
      <h2 className="text-center mb-4" style={{ color: '#3E8EDE', fontWeight: 'bold' }}>
        Event Promotion Packages
      </h2>
      <div className="table-responsive shadow-lg" style={{ borderRadius: '1px', overflow: 'hidden' }}>
        <Table striped bordered hover>
          <thead style={{ backgroundColor: '#F5F5F5', textAlign: 'center', fontWeight: 'bold' }}>
            <tr>
              <th>Service</th>
              <th>Basic Plan</th>
              <th>Premium Plan</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Event Listing Assistance</td>
              <td>CAD 40</td>
              <td>Included</td>
            </tr>
            <tr>
              <td>Social Media Highlights</td>
              <td>1 Free Post</td>
              <td>2 Posts + 1 Story</td>
            </tr>
            <tr>
              <td>Social Media Ad Campaign</td>
              <td>CAD 180</td>
              <td>CAD 250</td>
            </tr>
            <tr>
              <td>Feature Your Event</td>
              <td>CAD 250 <br /> Weekly Charge</td>
              <td>CAD 300 <br /> Weekly Charge</td>
            </tr>
            <tr>
              <td>
                Email Campaigns
                <p>
                  <small className="text-muted">
                    (One round of mass email)
                  </small>
                </p>
              </td>
              <td>Not Included</td>
              <td>CAD 250</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="text-center mt-4">
        <Button variant="success">Request Callback</Button>
      </div>
    </Container>
    </div>
  );
};

export default PromotionalServices;